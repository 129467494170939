import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { globalCountryId } from '$/components/core/Localization/hooks/useLocalizationStore';
import { countriesQuery } from '$/services/usecases/internationalization/countries';

export const useCountryLanguageOptions = (selectedCountryId: string) => {
  const { t } = useTranslation();

  const { data } = useQuery(countriesQuery);
  const rawCountries = data?.response.payload;

  const countries = rawCountries
    ?.filter((country) => !country.isBrand)
    .toSorted((a, b) => {
      if (a.id === globalCountryId || b.id === globalCountryId) return -1;
      return t(`countries.${a.id}`, {
        defaultValue: a.id,
        lng: 'en',
      }).localeCompare(
        t(`countries.${b.id}`, { defaultValue: b.id, lng: 'en' }),
      );
    });

  const selectedCountry = rawCountries?.find(
    (country) => country.id === selectedCountryId,
  );

  const languages = selectedCountry?.languages.toSorted((a, b) => {
    return t(`languages.${a}`, { defaultValue: a, lng: 'en' }).localeCompare(
      t(`languages.${b}`, { defaultValue: b, lng: 'en' }),
    );
  });

  return { countries, selectedCountry, languages };
};
