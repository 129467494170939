import { AbsoluteCenter, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { MaterialDetailsModal } from '$/components/core/Collection/MaterialDetailsModal';
import { useIsMobile } from '$/hooks/useIsMobile';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { FavoriteDashboardContent } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteDashboardContent';
import { FavoriteFolderView } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteFolderView';
import { FavoriteModal } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteModal';
import { Material } from '$/services/usecases/materials';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export type FavoriteItem = {
  material: Material;
  parentFolders: string[];
  isGlobalFavoriteMaterial: boolean;
};

export const FavoriteDashboard = () => {
  const { t } = useTranslation();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const favorites = useFavoriteStore.useFavorites();
  const isMobile = useIsMobile();
  const user = useAuthenticationStore.useUser();

  useEffect(() => {
    if (user != null && favorites.length === 0) {
      void useFavoriteStore.getState().getFavorites(true);
    }
  }, [favorites.length, user]);

  const ref = useRef<HTMLDivElement>(null);

  if (favorites.length === 0) {
    return (
      <Stack
        pos='relative'
        gap='0'
        overflowY='auto'
        w='full'
        h='full'
        {...(!isMobile && {
          layerStyle: 'DashboardSection',
          p: '7',
        })}
      >
        <Heading as='h1' mb='8' fontSize='3xl'>
          {t('favorites.pageHeader')}
        </Heading>
        <AbsoluteCenter>
          <Stack
            alignItems='center'
            gap='6'
            p='8'
            bg='bodyBackground'
            border='1px solid'
            borderColor='border'
            borderRadius='12px'
          >
            <Heading as='h2' fontSize='xl'>
              {t('favorites.empty.title')}
            </Heading>
            <Icon icon='like' boxSize='3rem' fill='transparent' />
            <Text fontSize='sm' whiteSpace='pre-line'>
              {t('favorites.empty.description')}
            </Text>

            <Button as={Link} to='/collections' variant='black'>
              {t('favorites.empty.discoverCollections')}
            </Button>
          </Stack>
        </AbsoluteCenter>
      </Stack>
    );
  }

  return (
    <Stack
      ref={ref}
      w='full'
      {...(!isMobile && {
        layerStyle: 'DashboardSection',
      })}
    >
      <StyledScrollWrapper>
        <Stack
          gap='0'
          overflowY='auto'
          w='full'
          {...(!isMobile && {
            p: '7',
          })}
        >
          <Show when={activeFolderId == null} fallback={FavoriteFolderView}>
            <FavoriteDashboardContent sectionRef={ref} />
          </Show>
          <MaterialDetailsModal />
          <FavoriteModal />

          <BackToTopButton
            containerRef={ref}
            right='65px'
            bottom='65px'
          ></BackToTopButton>
        </Stack>
      </StyledScrollWrapper>
    </Stack>
  );
};
