// Default actions that are selectable in the Action bar
export enum ModuleSendSelectAction {
  SelectPoly = 'selectPoly',
  CreateEditPoly = 'createEditPoly',
  DeleteSelectedPoly = 'deletePoly',
  MagicWand = 'magicWand',
  ColorChecker = 'colorChecker',
  Undo = 'undo',
  Redo = 'redo',
  ZoomWorkspace = 'zoomWorkspace',
  ZoomInnerfit = 'zoomInnerfit',
  ZoomSelection = 'zoomSelection',
  ZoomFullScreen = 'zoomFullScreen',
  AddGrid = 'addGrid',
  EditGrid = 'editGrid',
  UseBrush = 'useBrush',
  UsePatternBrush = 'usePatternBrush',
  UseRubber = 'useRubber',
  AddRect = 'addRect',
  EditRect = 'editRect',
  DeleteSelectedRect = 'deleteRect',
  AddEllipse = 'addEllipse',
  EditEllipse = 'editEllipse',
  DeleteSelectedEllipse = 'deleteEllipse',
  UseRetouchPaint = 'useRetouchPaint',
  UseRetouchGradient = 'useRetouchGradient',
  UseRetouchPattern = 'useRetouchPattern',
}

// Actions we are going to send
export enum ModuleSendAction {
  SelectElement = 'selectElement',
  RenameElement = 'renameElement',
  MoveElement = 'moveElement',
  DeleteElement = 'deleteElement',
  HoverElement = 'hoverOnElement',
  HoverOffElement = 'hoverOffElement',
  ResizeElement = 'elementSize',
  RepositionElement = 'elementPos',

  SelectLayer = 'selectLayer',
  RenameLayer = 'renameLayer',
  MoveLayer = 'moveLayer',
  DeleteLayer = 'deleteLayer',
  HoverLayer = 'hoverOnLayer',
  HoverOffLayer = 'hoverOffLayer',
  ResizeLayer = 'layerSize',
  RepositionLayer = 'layerPos',

  LoadPicture = 'loadPicture',
  AddElement = 'addElement',
  DoBooleanOperation = 'doBoolean',
  ZoomPercentage = 'zoom',
  LimitToMask = 'limitToMask',
  GetModuleState = 'getStatus',
  MoveGrid = 'movegrid',
  ElementSettings = 'elementSettings',
  HoverOnGrid = 'hoverOnGrid',
  HoverOffGrid = 'hoverOffGrid',
  ToggleTestPattern = 'testPattern',
  ResetGrid = 'resetGrid',

  ChangeLayerPointPosition = 'layerPointPos',
  ChangeLayerEditMode = 'layerEditMode',
  EnableSmoothLayer = 'smoothLayer',
  ClosePoly = 'closePoly',

  SetMagicWandTolerance = 'setMagicTolerance',
  SetMagicWandCircumference = 'setMagicCircumference',
  SetMagicWandMode = 'setMagicMode',

  SetRetouchPaintSize = 'retouchPaintSize',
  SetRetouchPaintType = 'retouchPaintType',
  SetRetouchPaintColor = 'setRetouchPaint',
  SetRetouchGradientColor = 'setRetouchGradient',
  SetRetouchPatternSize = 'retouchPatternSize',
  SetRetouchPatternType = 'retouchPatternType',
  SetRetouchPatternMaterial = 'retouchPatternPattern',

  SetMaterial = 'setMaterial',
  ColorChecker = 'colorchecker',

  SaveProject = 'saveProject',
  LoadProject = 'loadProject',

  SetColorCheckerSettings = 'colorCheckerSettings',
  ToggleVisibility = 'visibiltyToggle',
  SetBackgroundColor = 'setBackgroundColor',
}

export enum ModuleSendBrushShapeAction {
  BrushShape = 'brushType',
  RubberShape = 'rubberType',
  PatternBrushShape = 'patternBrushType',
  RetouchPaintType = 'retouchPaintType',
}

export enum ModuleSendBrushSizeAction {
  BrushSize = 'brushSize',
  RubberSize = 'rubberSize',
  PatternBrushSize = 'patternbrushSize',
  RetouchPaintSize = 'retouchPaintSize',
}

// Actions we are going to receive
export enum ModuleReceiveAction {
  Ready = 'ready',
  ProjectLoaded = 'tokenloaded',
  StatusSync = 'status',
  CommandReceived = 'commandreceived',
  NewLayer = 'newlayer',
  HoverOffObject = 'hoverofflayer',
  HoverObject = 'hoveronlayer',
  RightClick = 'righclick',
  ProjectSaved = 'projectsaved',
}
