export const userRoleDefinition = {
  free: {
    translation: 'roles.free' as const,
    mockProjects: 0,
    projectLimit: 0,
  },
  pro: {
    translation: 'roles.pro' as const,
    mockProjects: 8,
    projectLimit: 1000,
  },
  countryAdmin: {
    translation: 'roles.countryAdmin' as const,
    mockProjects: 8,
    projectLimit: 1000,
  },
  superAdmin: {
    translation: 'roles.superAdmin' as const,
    mockProjects: 8,
    projectLimit: 1000,
  },
};

export type UserRole = keyof typeof userRoleDefinition;
