import { createRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useSaveBeforeLeaveModalStore } from '$/pages/EditorPage/hooks/useSaveBeforeLeaveModalStore';
import { EditorPage } from '$/pages/EditorPage/pages/EditorPage';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { EditorRouteLayout } from '$/routes/Layouts/EditorLayout';
import { quickColors } from '$/services/usecases/editor/quickColors';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import {
  hideCookiebotTrigger,
  showCookiebotTrigger,
} from '$/utils/cookiebotUtils';

const EditorParamsSchema = z.object({
  projectId: z.string().optional(),
});

export const EditorRoute = createRoute({
  getParentRoute: () => EditorRouteLayout,
  path: '/editor/',
  component: EditorPage,
  validateSearch: (search) => EditorParamsSchema.parse(search),
  onLeave: () => {
    useEditorStore.getState().resetStore();
    showCookiebotTrigger();
  },
  onEnter: ({ context: { client } }) => {
    useEditorStore.getState().resetStore();
    useInspirationEditorStore.setState({ perspectiveFullscreen: false });
    useMaterialSearchStore.setState({ query: '' });
    useSaveBeforeLeaveModalStore.setState({
      isModalOpen: false,
      discardedState: '[]',
      hasDiscarded: false,
      previousComponentState: '[]',
    });
    hideCookiebotTrigger();
    void client
      .ensureQueryData(
        materialsQuery(
          {
            ids: quickColors.flat(),
            overwriteConfig: 'testfarben',
            demo: true,
          },
          false,
          true,
        ),
      )
      .then((res) => {
        const initialMaterial = res.materials.find(
          (material) => material.uniqueKey === quickColors[0][0],
        );

        if (initialMaterial != null) {
          useEditorStore.setState({ initialMaterialId: initialMaterial.id });
        }
      });
  },
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({ filter: undefined });
    void client.ensureQueryData(filterOptionsQuery()).then((filter) => {
      useCollectionStore.setState({ filter });
    });
  },
});
