import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { LanguageCard } from '$/pages/AdminPages/pages/LanguagesPage/components/LanguageCard';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { adminLanguagesQuery } from '$/services/usecases/admin/languages';

export const LanguageList = () => {
  const languages = useQuery(adminLanguagesQuery);
  const countries = useQuery(brandCountriesQuery);

  if (!languages.data?.response?.payload) return null;

  return (
    <Stack gap='4'>
      {languages.data.response.payload.map((language) => {
        const languageCountries = countries.data?.response?.payload
          .filter((country) => country.languageIds.includes(language.id))
          ?.map((country) => country.id);

        return (
          <LanguageCard
            key={language.id}
            language={language}
            countries={languageCountries ?? []}
          />
        );
      })}
    </Stack>
  );
};
