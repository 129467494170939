import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FeedbackSection } from '$/components/core/FeedbackModal/components/FeedbackSection';
import {
  HookFormEditInput,
  HookFormEditInputProps,
} from '$/components/core/Form/HookFormEditInput';
import { useManagedBrandCountry } from '$/pages/AdminPages/hooks/useManagedBrandCountry';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { countriesQuery } from '$/services/usecases/internationalization/countries';
import { emailFormatRegex } from '$/utils/regexUtils';

type ContactHelpData = {
  email: string;
};

const EditInputField = (props: HookFormEditInputProps<ContactHelpData>) => (
  <HookFormEditInput<ContactHelpData> {...props} />
);

export const ContactHelpCard = () => {
  const { t } = useTranslation();

  const managedBrandCountry = useManagedBrandCountry();
  const updateBrandCountryMutation = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: countriesQuery.queryKey,
      });
    },
  });

  const formMethods = useForm<ContactHelpData>({
    defaultValues: { email: managedBrandCountry?.contactEmail ?? '' },
  });

  const onSubmit = async (data: ContactHelpData) => {
    if (!managedBrandCountry) return;

    await updateBrandCountryMutation.mutateAsync({
      id: managedBrandCountry.id,
      brandCountry: {
        contactEmail: data.email,
      },
    });
  };

  useEffect(() => {
    formMethods.reset({ email: managedBrandCountry?.contactEmail ?? '' });
  }, [managedBrandCountry, formMethods]);

  return (
    <Box w='500px'>
      <FeedbackSection
        additionalBlock={
          <FormProvider {...formMethods}>
            <Stack as='form' onSubmit={(e) => e.preventDefault()}>
              <EditInputField
                placeholder={t('feedback.emailPlaceholder')}
                accessor='email'
                label=''
                value={managedBrandCountry?.contactEmail ?? ''}
                isLoading={updateBrandCountryMutation.isPending}
                hideErrorLabel
                onSave={async () => {
                  const isValid = await formMethods.trigger();

                  if (isValid) {
                    await formMethods.handleSubmit(onSubmit)();
                  }
                }}
                registerOptions={{
                  required: t('feedback.errorMessages.isRequired'),
                  pattern: {
                    value: emailFormatRegex,
                    message: t('feedback.errorMessages.wrongFormat'),
                  },
                }}
              />
              <HStack justify='space-between'>
                <Text color='lighterText' fontSize='xs'>
                  {t('feedback.inputEmail')}
                </Text>
                <Text color='form.errorText'>
                  {formMethods.formState.errors.email?.message?.toString()}
                </Text>
              </HStack>
            </Stack>
          </FormProvider>
        }
        icon='question'
        title={t('feedback.helpTitle')}
        description={t('feedback.helpDescriptionWithoutEmail')}
      />
    </Box>
  );
};
