import {
  Center,
  VStack,
  Heading,
  Button,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HookFormErrorList } from '$/components/core/Form/HookFormErrorList';
import { HookFormPasswordInput } from '$/components/core/Form/HookFormPasswordInput';
import { useToast } from '$/hooks/useToast';
import { NewPasswordRoute } from '$/routes/Account/NewPasswordRoute';
import { resetPasswort } from '$/services/usecases/authentication';
import { validatePassword } from '$/utils/stringUtils';

type NewPasswordData = {
  newPassword: string;
  repeatedNewPassword: string;
};

export const NewPasswordPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const formMethods = useForm<NewPasswordData>({ mode: 'onTouched' });
  const { otp, email } = NewPasswordRoute.useSearch();
  const navigate = useNavigate();

  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onValidatePassword = (value: string) => {
    const errors = validatePassword(value, t);
    setPasswordErrors(errors);
    return errors.length === 0;
  };

  const onSubmit = async (data: NewPasswordData) => {
    try {
      setLoading(true);
      const response = await resetPasswort(
        data.newPassword,
        otp.toString(),
        email,
      );

      if (response.isSuccessful) {
        void navigate({ to: '/password-reset-successful' });
      }
    } catch (_) {
      toast(
        t('dashboard.authentication.changePassword.errorTitle'),
        'error',
        t('dashboard.authentication.changePassword.errorDescription'),
      );
    }

    setLoading(false);
  };

  return (
    <Center h='100dvh'>
      <VStack
        layerStyle='DashboardSection'
        alignItems='flex-start'
        gap='2.5rem'
        w='full'
        maxW='31rem'
        p='2.5rem'
      >
        <Heading fontSize='3xl'>
          {t('dashboard.authentication.resetPassword.action')}
        </Heading>
        <FormProvider {...formMethods}>
          <Stack
            as='form'
            gap='2rem'
            w='full'
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <Text color='lighterText' fontSize='sm'>
              {t('dashboard.authentication.changePassword.newInfo')}
            </Text>
            <HookFormPasswordInput<NewPasswordData>
              accessor='newPassword'
              label={t('dashboard.authentication.changePassword.newPassword')}
              registerOptions={{
                required: t(
                  'dashboard.authentication.errorMessages.isRequired',
                ),
                validate: onValidatePassword,
              }}
              description={t(
                'dashboard.authentication.errorMessages.passwordRules',
              )}
            />
            <HookFormPasswordInput<NewPasswordData>
              accessor='repeatedNewPassword'
              label={t(
                'dashboard.authentication.changePassword.repeatPassword',
              )}
              registerOptions={{
                required: t(
                  'dashboard.authentication.errorMessages.isRequired',
                ),
                validate: (value) =>
                  value === formMethods.getValues('newPassword') ||
                  t('dashboard.authentication.errorMessages.passwordMismatch'),
              }}
            />

            {passwordErrors.length !== 0 && (
              <HookFormErrorList
                errors={passwordErrors}
                description={t(
                  'dashboard.authentication.changePassword.invalidRequirements',
                )}
              />
            )}

            <HStack justify='center' w='full'>
              <Button
                isDisabled={!formMethods.formState.isValid}
                isLoading={loading}
                type='submit'
              >
                {t('dashboard.authentication.changePassword.action')}
              </Button>
            </HStack>
          </Stack>
        </FormProvider>
      </VStack>
    </Center>
  );
};
