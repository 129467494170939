import { queryOptions } from '@tanstack/react-query';

import { queryClient, STANDARD_STALE_TIME } from '$/services/fetcher';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { producerOptionsQuery } from '$/services/usecases/filter/queries';

export const getAllProducers = async (countryId?: string) => {
  const [filterOptions, brandCountries] = await Promise.all([
    queryClient.fetchQuery(producerOptionsQuery),
    queryClient.fetchQuery(brandCountriesQuery),
  ]);

  const producer = filterOptions.general
    .find((item) => item.searchName === 'producer')!
    .items.map((item) => ({ id: item.intName, name: item.displayName }));

  const sortedProducerIds = brandCountries.response.payload.find(
    (item) => item.id === countryId,
  )?.producerIds;

  if (!sortedProducerIds || !sortedProducerIds.length) {
    return producer;
  }

  const sortedProducers = sortedProducerIds.map(
    (id) => producer.find((item) => item.id === id)!,
  );

  return sortedProducers;
};

export const adminProducerQuery = (countryId?: string) =>
  queryOptions({
    queryKey: ['admin-producer', countryId],
    queryFn: () => getAllProducers(countryId),
    staleTime: STANDARD_STALE_TIME,
  });
