import { queryOptions } from '@tanstack/react-query';

import { backendEndpoints } from '$/services/endpoints';
import { fetcher, STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  CreateUpdateCountryAdminsSchema,
  GetAllCountryAdminsSchema,
} from '$/services/usecases/admin/mapper/countryAdmins';
import { validateToken } from '$/services/usecases/authentication';
import { UserRole } from '$/services/usecases/authentication/mapper/jwt';

export const getAllCountryAdmins = async () => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_USERS_URL, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetAllCountryAdminsSchema.parse(data),
  });
};

export const countryAdminsQuery = queryOptions({
  queryKey: ['countryAdmins'],
  queryFn: () => getAllCountryAdmins(),
  staleTime: STANDARD_STALE_TIME,
});

export const createCountryAdmin = async (
  email: string,
  managedCountries: string[],
) => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_USERS_URL, {
    method: 'POST',
    body: { email, managedCountries },
    includeCredentials: true,
    parser: (data) => CreateUpdateCountryAdminsSchema.parse(data),
  });
};

export const updateCountryAdmin = async (payload: {
  id: string;
  managedCountries?: string[];
  role?: UserRole;
  isActiveAdmin?: boolean;
}) => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_USERS_URL}/${payload.id}`, {
    method: 'PATCH',
    body: {
      managedCountries: payload.managedCountries,
      role: payload.role,
      isActiveAdmin: payload.isActiveAdmin,
    },
    includeCredentials: true,
    parser: (data) => CreateUpdateCountryAdminsSchema.parse(data),
  });
};

export const deleteCountryAdmin = async (id: string) => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_USERS_URL}/${id}`, {
    method: 'DELETE',
    includeCredentials: true,
    parser: (data) => CreateUpdateCountryAdminsSchema.parse(data),
  });
};
