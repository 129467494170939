import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '$/hooks/useToast';
import { updateLanguageFile } from '$/services/usecases/admin/languages';

export const useUpdateLanguageFile = () => {
  const { t } = useTranslation();
  const showToast = useToast();

  return useMutation({
    mutationFn: updateLanguageFile,
    onSuccess: (res) => {
      if (res.isSuccessful) {
        return showToast(
          t('admin.languages.generalSuccess'),
          'success',
          t('admin.languages.updateLanguageFileSuccess'),
        );
      }

      showToast(
        t('admin.languages.generalError'),
        'error',
        t('admin.languages.updateLanguageFileError', {
          percentage: res.response.payload?.percentage ?? 0,
        }),
      );
    },
  });
};
