import { Container, Grid, GridItem, Stack } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-router';
import { FunctionComponent } from 'react';

import { FeedbackModal } from '$/components/core/FeedbackModal';
import { ProfileModal } from '$/components/core/Profile/ProfileModal';
import { AdminNavbar } from '$/components/layouts/BaseAdminLayout/components/AdminNavbar';
import { Header } from '$/components/layouts/BaseDashboardLayout/components/Header';
import { useIsMobile } from '$/hooks/useIsMobile';

export const BaseAdminLayout: FunctionComponent = () => {
  const isMobile = useIsMobile();

  return (
    <Container
      maxW={{ base: 'container.2xl', lg: '100%' }}
      h='100dvh'
      p={{ base: '16px', mobile: '32px', xl: '64px' }}
    >
      <Grid
        gap={{ base: '16px', xl: '32px' }}
        templateRows='auto minmax(0, 1fr)'
        templateColumns='auto 1fr'
        h='100%'
      >
        <GridItem colSpan={2}>
          <Header />
        </GridItem>
        <GridItem>
          <AdminNavbar />
        </GridItem>
        <GridItem display='flex' minW='0' colSpan='auto'>
          <Stack
            w='full'
            {...(!isMobile && {
              layerStyle: 'DashboardSection',
            })}
          >
            <Outlet />
          </Stack>
        </GridItem>
      </Grid>
      <ProfileModal />
      <FeedbackModal />
    </Container>
  );
};
