import {
  Center,
  Grid,
  HStack,
  IconButton,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useUpdateLanguageFile } from '$/pages/AdminPages/pages/LanguagesPage/hooks/useUpdateLanguageFile';
import { queryClient } from '$/services/fetcher';
import {
  adminLanguagesQuery,
  updateLanguage,
} from '$/services/usecases/admin/languages';
import { AdminLanguage } from '$/services/usecases/admin/mapper/languages';
import { countriesQuery } from '$/services/usecases/internationalization/countries';

interface Props {
  language: AdminLanguage;
  countries: string[];
}

export const LanguageCard: FC<Props> = ({ language, countries }) => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(language.isActive);

  const languageFileMutation = useUpdateLanguageFile();

  const updateLanguageMutation = useMutation({
    mutationFn: updateLanguage,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: adminLanguagesQuery.queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: countriesQuery.queryKey,
      });
    },
  });

  useEffect(() => {
    setIsChecked(language.isActive);
  }, [language.isActive]);

  const toggleLanguage = () => {
    updateLanguageMutation.mutate({
      id: language.id,
      isActive: !language.isActive,
    });

    setIsChecked(!language.isActive);
  };

  return (
    <HStack
      gap='0'
      px='8'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='md'
    >
      <Grid
        templateColumns='minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
        w='full'
      >
        <HStack>
          <Center
            minW='42px'
            px='2'
            py='1'
            border='1px solid'
            borderColor='borderHover'
            borderRadius='sm'
          >
            {language.id}
          </Center>
          <Text fontWeight='bold'>{t(`languages.${language.id}`)}</Text>
        </HStack>

        <Stack justify='center'>
          <Text>
            {countries
              .map((country) =>
                t(`countries.${country}`, { defaultValue: country }),
              )
              .join(', ')}
          </Text>
        </Stack>

        <HStack justify='flex-end' gap='8'>
          <HStack>
            <Switch
              disabled={updateLanguageMutation.isPending}
              isChecked={isChecked}
              onChange={toggleLanguage}
            />
            <Text> {t('admin.languages.isActive')}</Text>
          </HStack>

          <IconButton
            aria-label='refresh'
            isLoading={languageFileMutation.isPending}
            onClick={() => languageFileMutation.mutate(language.id)}
            variant='secondary'
          >
            <Icon icon='refresh' w='14px' />
          </IconButton>
        </HStack>
      </Grid>
    </HStack>
  );
};
