import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExportFileTypeSelection,
  ExportType,
} from '$/components/core/Editor/ExportFileTypeSelection';
import { useToast } from '$/hooks/useToast';
import { trackEvent } from '$/logger';
import { ExportOptionsSelection } from '$/pages/InspirationEditorPage/components/ExportModal/ExportOptionsSelection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export type ExportFormData = {
  exportType: ExportType;
  variants: number[];
  perspectives: number[];
};

const reducer = (state: ExportFormData, newData: Partial<ExportFormData>) => {
  return { ...state, ...newData };
};

export const ExportInspirationModal: FC<Props> = ({ isOpen, onClose }) => {
  const [hideMaterials, setHideMaterials] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    exportType: 'jpg',
    variants: [0],
    perspectives: [0],
  });

  const onExport = async () => {
    setIsLoading(true);
    if (scene != null) {
      trackEvent(
        'ExportProjectButton',
        'Click',
        `Inspiration, ${state.exportType.toUpperCase()}, ${state.variants.length}, ${state.variants.length}`,
      );

      try {
        const exportModule = await import(
          '$/pages/InspirationEditorPage/components/ExportModal/utils/ExportHelper'
        );
        await exportModule.generateExportZipFolderWithContent(
          state,
          scene,
          sceneLayersVariants,
          sceneVariantLabelOrder,
          t,
          hideMaterials,
        );
      } catch (error: unknown) {
        console.error(error);
        toast(
          'Something went wrong',
          'error',
          'There was an error generating your export',
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: 'unset', mobile: '500px' }}
        maxW='500px'
        mx='4'
        p={{ base: '4', mobile: '10' }}
      >
        <HStack justify='space-between' pb='4'>
          <Text fontSize={{ base: 'h10', mobile: 'h7' }} fontWeight='bold'>
            {t('editor.exportText.title')}
          </Text>
          <ModalCloseButton pos='static' />
        </HStack>

        <ModalBody p='0'>
          <Stack gap={{ base: '4', mobile: '6' }}>
            <ExportFileTypeSelection
              exportType={state.exportType}
              dispatch={dispatch}
              hideMaterials={hideMaterials}
              onToggleHideMaterials={setHideMaterials}
            />

            <ExportOptionsSelection state={state} dispatch={dispatch} />
          </Stack>

          <HStack justifyContent='center' mt={{ base: '6', mobile: '10' }}>
            <Button
              w={{ base: 'full', mobile: 'fit-content' }}
              fontSize='sm'
              isDisabled={
                state.variants.length == 0 || state.perspectives.length == 0
              }
              isLoading={isLoading}
              onClick={onExport}
            >
              {t('editor.exportText.title')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
