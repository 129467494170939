import { Center, VStack, Button, HStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormInputProps,
  HookFormInput,
} from '$/components/core/Form/HookFormInput';
import { useToast } from '$/hooks/useToast';

type LoginData = {
  username: string;
  password: string;
};

const LoginInput = (props: HookFormInputProps<LoginData>) => (
  <HookFormInput<LoginData> {...props} />
);

export const LoginPage = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const formMethods = useForm<LoginData>();

  const onLogin = (data: LoginData) => {
    const username = import.meta.env.VITE_LOGIN_USER;
    const password = import.meta.env.VITE_LOGIN_PASSWORD;

    if (username === data.username && password === data.password) {
      localStorage.setItem('isAuthenticated', 'true');
      location.reload();
    } else {
      toast(
        'Falsche Logindaten',
        'error',
        'Username oder Passwort sind nicht korrekt',
      );
    }
  };

  return (
    <Center h='100dvh'>
      <FormProvider {...formMethods}>
        <VStack
          as='form'
          gap='8'
          p='6'
          bg='background'
          border='1px solid'
          borderColor='border'
          borderRadius='12px'
          onSubmit={formMethods.handleSubmit(onLogin)}
        >
          <LoginInput
            accessor='username'
            label='Username'
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: 'Username',
            })}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
            }}
          />
          <LoginInput
            type='password'
            accessor='password'
            label={t('dashboard.authentication.passwordLabel')}
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: t('dashboard.authentication.passwordLabel'),
            })}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
            }}
          />
          <HStack justifyContent='stretch' gap='4' w='full' mt='6'>
            <Button flexGrow='1' type='submit' variant='primary'>
              {t('dashboard.header.signIn')}
            </Button>
          </HStack>
        </VStack>
      </FormProvider>
    </Center>
  );
};
