import { queryOptions } from '@tanstack/react-query';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  getAdminScenes,
  getAllCategories,
  getAllScenes,
  getCompleteScene,
} from '$/services/usecases/scenes';

export const scenesQuery = queryOptions({
  queryKey: ['scenes'],
  queryFn: ({ signal }) => getAllScenes(signal),
  staleTime: STANDARD_STALE_TIME,
});

export const adminScenesQuery = queryOptions({
  queryKey: ['adminScenes'],
  queryFn: ({ signal }) => getAdminScenes(signal),
  staleTime: STANDARD_STALE_TIME,
});

export const completeSceneQuery = (id: string) =>
  queryOptions({
    queryKey: ['completeScene', id],
    queryFn: ({ signal }) => getCompleteScene(id, signal),
    staleTime: STANDARD_STALE_TIME,
  });

export const categoriesQuery = queryOptions({
  queryKey: ['categories'],
  queryFn: ({ signal }) => getAllCategories(signal),
  staleTime: STANDARD_STALE_TIME,
});
