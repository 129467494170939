import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const BrandCountrySchema = z.object({
  id: z.string(),
  isActive: z.boolean(),
  isBrand: z.boolean(),
  languageIds: z.array(z.string()),
  inspirationIds: z.array(z.string()),
  collectionIds: z.array(z.string()),
  producerIds: z.array(z.string()),
  contactEmail: z.string(),
});

export type BrandCountry = z.infer<typeof BrandCountrySchema>;

export const GetAllBrandCountriesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.array(BrandCountrySchema),
});

export const CreateUpdateBrandCountriesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: BrandCountrySchema,
});
