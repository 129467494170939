import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CountryAdminLanguageCard } from '$/pages/AdminPages/pages/LanguagesPage/components/CountryAdminLanguageCard';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { adminLanguagesQuery } from '$/services/usecases/admin/languages';

export const CountryAdminLanguageList = () => {
  const languages = useQuery(adminLanguagesQuery);
  const countries = useQuery(brandCountriesQuery);
  const user = useAuthenticationStore.useUser();

  const countryToUpdate = countries?.data?.response.payload.find(
    (country) => country.id === user?.managedCountries[0],
  );

  if (!languages.data?.response?.payload || !countryToUpdate) {
    return null;
  }

  return (
    <Stack gap='4'>
      {languages.data.response.payload.map((language) => (
        <CountryAdminLanguageCard
          key={language.id}
          language={language}
          countryToUpdate={countryToUpdate}
        />
      ))}
    </Stack>
  );
};
