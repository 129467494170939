import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { CountryAdminCard } from '$/pages/AdminPages/pages/UserPage/components/CountryAdminCard';
import { EditCountryAdminModal } from '$/pages/AdminPages/pages/UserPage/components/EditCountryAdminModal';
import { countryAdminsQuery } from '$/services/usecases/admin/countryAdmins';

export const CountryAdmins = () => {
  const { data } = useQuery(countryAdminsQuery);
  if (!data?.response?.payload) return null;

  return (
    <Stack gap='4'>
      {data.response.payload.map((user) => (
        <CountryAdminCard key={user.id} user={user} />
      ))}
      <EditCountryAdminModal />
    </Stack>
  );
};
