import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputProps,
  FormControlProps,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { InfoTooltip } from '$/components/common/InfoTooltip';

export interface HookFormInputProps<T extends FieldValues> extends InputProps {
  accessor: FieldPath<T>;
  label: string;
  registerOptions?: RegisterOptions<T>;
  formControlProps?: FormControlProps;
  inputLeftElement?: JSX.Element;
  inputRightElement?: JSX.Element;
  rightElement?: JSX.Element;
  ignoreBlur?: boolean;
  showDefaultBorder?: boolean;
  description?: string;
  externalError?: boolean;
  hideErrorLabel?: boolean;
}

export const HookFormInput = <T extends FieldValues>({
  label,
  accessor,
  registerOptions,
  formControlProps,
  inputLeftElement,
  inputRightElement,
  rightElement,
  ignoreBlur = false,
  showDefaultBorder = false,
  externalError = false,
  hideErrorLabel = false,
  description,
  ...inputProps
}: HookFormInputProps<T>) => {
  const [isTouched, setIsTouched] = useState(false);

  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  const isInvalid =
    (get(errors, accessor) != null && !inputProps.isReadOnly) || externalError;
  const borderSettings = isInvalid ? '2px solid' : undefined;
  const borderColor = isInvalid ? 'form.errorBorder' : undefined;
  const showStateBorder = (isTouched && !showDefaultBorder) || externalError;

  const registerProps = register(accessor, registerOptions);

  return (
    <FormControl
      isInvalid={isInvalid}
      onBlur={(e) => {
        if (!e.target.ariaReadOnly && !ignoreBlur) setIsTouched(true);
      }}
      {...formControlProps}
    >
      <HStack alignItems='baseline' justifyContent='space-between'>
        <HStack alignItems='baseline' gap='0'>
          <FormLabel
            color='text'
            fontSize='sm'
            whiteSpace='nowrap'
            htmlFor={accessor}
          >
            {label}
          </FormLabel>
          <Show when={description != null}>
            <InfoTooltip
              info={description ?? ''}
              icon={
                <Icon w='12px' color='lighterText' cursor='help' icon='info' />
              }
            />
          </Show>
        </HStack>
        {!hideErrorLabel && (
          <FormErrorMessage mt='0' color='form.errorText'>
            {get(errors, accessor)?.message?.toString()}
          </FormErrorMessage>
        )}
      </HStack>
      <InputGroup>
        {inputLeftElement && (
          <InputLeftElement>{inputLeftElement}</InputLeftElement>
        )}
        <Input
          border={showStateBorder ? borderSettings : undefined}
          borderColor={showStateBorder ? borderColor : undefined}
          _active={showStateBorder ? {} : undefined}
          _focus={showStateBorder ? { borderColor: borderColor } : undefined}
          _focusVisible={{}}
          id={accessor}
          {...registerProps}
          {...inputProps}
          onChange={(event) => {
            inputProps.onChange?.(event);
            void registerProps.onChange(event);
          }}
        />
        {inputRightElement && (
          <InputRightElement w='fit-content'>
            {inputRightElement}
          </InputRightElement>
        )}
        {rightElement}
      </InputGroup>
    </FormControl>
  );
};
