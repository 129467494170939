import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { i18n, persistLocalization } from '$/components/core/Localization/18n';

export const globalCountryId = 'xw';

export const getActiveBrandCountryKey = () =>
  useLocalizationStore.getState().brand ??
  useLocalizationStore.getState().country;

export type State = {
  language: string;
  country: string;
  brand: string | null;
  is18nModalOpen: boolean;
  isInitialSelection: boolean;
};

type Actions = {
  applyLocalization: (payload: {
    country: string;
    language: string;
  }) => Promise<void>;
  onOpen18nModal: () => void;
  onClose18nModal: () => void;
};

export const initial: State = {
  language: 'en',
  country: globalCountryId,
  is18nModalOpen: false,
  isInitialSelection: false,
  brand: null,
};

export const store = create<State & Actions>()((set, get) => ({
  ...initial,
  applyLocalization: async (payload) => {
    await i18n.changeLanguage(payload.language);
    persistLocalization(payload);
    if (payload.country !== get().country) {
      location.reload();
    }
    set(payload);
  },
  onOpen18nModal: () => set({ is18nModalOpen: true }),
  onClose18nModal: () => set({ is18nModalOpen: false }),
}));

export const useLocalizationStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
