import {
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { BrandCountrySelection } from '$/pages/AdminPages/components/BrandCountrySelection';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountryProperty,
} from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { Collection } from '$/services/usecases/collections';

interface Props {
  collection: Collection | null;
  brandCountries: BrandCountry[];
  isOpen: boolean;
  onClose: () => void;
}

export const EditCollectionModal: FC<Props> = ({
  brandCountries,
  isOpen,
  onClose,
  collection,
}) => {
  const { t } = useTranslation();
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const [selectedBrandCountries, setSelectedBrandCountries] = useState<
    BrandCountry[]
  >([]);
  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  const onChangeCountries: Dispatch<SetStateAction<BrandCountry[]>> = (
    state,
  ) => {
    setHasBeenChanged(true);
    setSelectedBrandCountries(state);
  };

  const showToast = useToast();

  const onCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    setHasBeenChanged(false);
    if (collection == null) {
      setSelectedBrandCountries([]);
      return;
    }

    setSelectedBrandCountries(
      brandCountries.filter((brandCountry) =>
        brandCountry.collectionIds.includes(collection.intName),
      ),
    );
  }, [brandCountries, collection, t]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateBrandCountryProperty,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (res.isSuccessful) {
        onCloseModal();
        return;
      }

      showToast(
        t('admin.userManagement.addUser.generalError'),
        'error',
        cloudMessageTranslation(res.response.message),
      );
    },
  });

  const onSubmit = () => {
    if (collection == null) {
      return;
    }
    mutate({
      propertyType: 'collectionIds',
      itemId: collection.intName,
      brandCountryIds: selectedBrandCountries.map(
        (brandCountry) => brandCountry.id,
      ),
    });
  };

  if (!collection) {
    return null;
  }

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onCloseModal} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p='10'>
          <Heading mb='4' fontSize='h7'>
            {t('admin.collections.editModal.title')}
          </Heading>
          <Text mb='2' color='neutralText' fontSize='xl' fontWeight='bold'>
            {collection.producerShowName}
          </Text>
          <Text mb='6' fontSize='xl' fontWeight='bold'>
            {collection.showName}
          </Text>
          <BrandCountrySelection
            selectedBrandCountries={selectedBrandCountries}
            setSelectedBrandCountries={onChangeCountries}
            allBrandCountries={brandCountries}
            descriptionText={t(
              'admin.collections.editModal.usedBrandCountriesDescription',
            )}
          />

          <HStack justify='space-between' mt='10'>
            <Button
              isLoading={isPending}
              onClick={onCloseModal}
              variant='ghost'
            >
              {t('general.cancel')}
            </Button>
            <Button
              isDisabled={!hasBeenChanged}
              isLoading={isPending}
              onClick={onSubmit}
            >
              {t('admin.inspirations.editModal.saveChangesButton')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
