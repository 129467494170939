import { Box, HStack, Text } from '@chakra-ui/react';
import { Draggable } from '@hello-pangea/dnd';
import { FC } from 'react';

import { getNewYPosition } from '$/pages/EditorPage/utils/dndFencing';

interface Props {
  name: string;
  position: number;
  dropableObject: HTMLElement | null;
  topOffset: number;
  height: number;
  isLoading?: boolean;
}

export const ProducerListItem: FC<Props> = ({
  position,
  name,
  dropableObject,
  height,
  topOffset,
  isLoading,
}) => {
  return (
    <Draggable
      draggableId={`producer-${name}`}
      index={position}
      isDragDisabled={isLoading}
    >
      {(provided) => {
        if (
          provided.draggableProps.style?.transform != null &&
          dropableObject != null
        ) {
          const y = provided.draggableProps.style?.transform
            .split(',')[1]
            .replace('px)', '');

          provided.draggableProps.style.transform = `translate(0px, ${getNewYPosition(dropableObject, position, Number(y), height, topOffset)}px)`;
        }

        return (
          <Box
            ref={provided.innerRef}
            py='2'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <HStack
              gap='6'
              px='8'
              py='4'
              bg='background'
              border='1px solid'
              borderColor='border'
              borderRadius='lg'
            >
              <Text>{position + 1}</Text>
              <Text fontWeight='bold'>{name}</Text>
            </HStack>
          </Box>
        );
      }}
    </Draggable>
  );
};
