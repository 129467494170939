import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { useAdminUserStore } from '$/pages/AdminPages/pages/UserPage/stores/useAdminUserStore';
import { queryClient } from '$/services/fetcher';
import {
  countryAdminsQuery,
  deleteCountryAdmin,
} from '$/services/usecases/admin/countryAdmins';

export const DeleteCountryAdminForm = () => {
  const { t } = useTranslation();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const activeUser = useAdminUserStore.useActiveEditUser()!;
  const setModalType = useAdminUserStore.useSetEditModalType();
  const onModalClose = useAdminUserStore.useOnEditModalClose();

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const deleteUser = async () => {
    setDeleteLoading(true);

    const res = await deleteCountryAdmin(activeUser.id).finally(() =>
      setDeleteLoading(false),
    );

    if (res.isSuccessful) {
      await queryClient.invalidateQueries({
        queryKey: countryAdminsQuery.queryKey,
      });

      return onModalClose();
    }

    showToast(
      t('admin.userManagement.addUser.generalError'),
      'error',
      cloudMessageTranslation(res.response.message),
    );
  };

  return (
    <Stack gap='10'>
      <Text fontSize='sm'>
        <Trans
          i18nKey='admin.userManagement.editUser.deleteWarning'
          values={{ name: activeUser?.firstName + ' ' + activeUser?.lastName }}
        />
      </Text>
      <HStack justifyContent='space-between' w='full'>
        <Button
          color='lighterText'
          fontSize='sm'
          onClick={() => setModalType('update_admin')}
          type='submit'
          variant='ghost'
        >
          {t('admin.userManagement.addUser.cancel')}
        </Button>
        <Button
          px='6'
          py='3'
          fontSize='sm'
          isLoading={deleteLoading}
          onClick={deleteUser}
          variant='danger'
        >
          {t('admin.userManagement.editUser.delete')}
        </Button>
      </HStack>
    </Stack>
  );
};
