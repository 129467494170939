import { Box } from '@chakra-ui/react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { useQuery } from '@tanstack/react-query';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { ProducerListItem } from '$/pages/AdminPages/pages/ProducersPage/components/ProducerListItem';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { useUpdateBrandCountry } from '$/services/usecases/admin/mutations/useUpdateBrandCountry';
import { adminProducerQuery } from '$/services/usecases/admin/producers';
import { collectionsQuery } from '$/services/usecases/collections';
import { reorderArray } from '$/utils/arrayUtils';

export const ProducerList = () => {
  const droppableRef: MutableRefObject<HTMLElement | null> =
    useRef<HTMLDivElement | null>(null);
  const containerRef: MutableRefObject<HTMLElement | null> =
    useRef<HTMLDivElement | null>(null);

  const [orderedProducers, setOrderedProducers] = useState<
    { id: string; name: string }[]
  >([]);

  const { mutate, isPending } = useUpdateBrandCountry();
  const user = useAuthenticationStore.useUser();
  const { data } = useQuery(adminProducerQuery(user?.managedCountries[0]));
  const collections = useQuery(collectionsQuery);
  const brandCountries = useQuery(brandCountriesQuery);

  const activeBrandCountry = brandCountries.data?.response.payload.find(
    (item) => item.id === user?.managedCountries[0],
  );

  useEffect(() => {
    if (data && data.length) setOrderedProducers(data);

    const container = document.getElementById('producerContainer');
    containerRef.current = container;
  }, [data]);

  if (!data) return null;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorderArray(
      orderedProducers,
      result.source.index,
      result.destination.index,
    );

    if (!user || !user.managedCountries.length) return;

    const sortedProducers = items.map((item) => item.id);

    const updatedBrandCountryData: Partial<BrandCountry> = {
      producerIds: sortedProducers,
    };

    if (
      collections.data &&
      collections.data?.length > 0 &&
      activeBrandCountry != null
    ) {
      const sortedCollections = collections.data
        .filter((collection) =>
          activeBrandCountry.collectionIds.includes(collection.intName),
        )
        .sort(
          (a, b) =>
            sortedProducers.indexOf(a.producerIntName) -
            sortedProducers.indexOf(b.producerIntName),
        )
        .map((collection) => collection.intName);

      if (sortedCollections.length > 0)
        updatedBrandCountryData.collectionIds = sortedCollections;
    }

    mutate({
      brandCountry: updatedBrandCountryData,
      id: user?.managedCountries[0],
    });

    setOrderedProducers(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='producer-droppable'>
        {(provided) => (
          <Box
            ref={(ref) => {
              provided.innerRef(ref);
              droppableRef.current = ref;
            }}
          >
            {orderedProducers.map((producer, index) => (
              <ProducerListItem
                key={producer.id}
                position={index}
                name={producer.name}
                dropableObject={droppableRef.current}
                height={containerRef.current?.offsetHeight ?? 0}
                topOffset={containerRef.current?.offsetTop ?? 0}
                isLoading={isPending}
              />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
