import { array, boolean, number, object, string, z } from 'zod';

const WorkModeOptionsEnum = z.enum([
  'addRect',
  'addPoly',
  'createeditpoly',
  'addEllipse',
  'selectPoly',
  'brush',
  'rubber',
  'grid',
  'image',
  'stencil',
  'magicWand',
  'retouchPaint',
  'retouchPattern',
  'retouchGradient',
]);
export type WorkMode = z.infer<typeof WorkModeOptionsEnum>;

const ZoomTypeOptionsEnum = z.enum([
  'zoomPercent',
  'zoomInnerFit',
  'zoomWorkspace',
  'zoomSelection',
]);

const LayerEditModeEnum = z.enum(['select', 'add', 'delete']);
export type LayerEditMode = z.infer<typeof LayerEditModeEnum>;

const BooleanModeOptionsEnum = z.enum(['exclude', 'union', 'substract']);

const magicModeOptionsEnum = z.enum(['add', 'sub', 'new']);

export type MagicModeOptions = z.infer<typeof magicModeOptionsEnum>;

export type ZoomType = z.infer<typeof ZoomTypeOptionsEnum>;

const BrushTypeOptionsEnum = z.enum(['round', 'square']);
export type BrushType = z.infer<typeof BrushTypeOptionsEnum>;

const ObjectModeOptionsEnum = z.enum(['poly', 'bitmap']);
export type ObjectMode = z.infer<typeof ObjectModeOptionsEnum>;

export const EditorStatusSchema = object({
  action: string(),
  data: object({
    active: object({
      elementIdx: number().nullable().optional(),
      layerIdx: number().nullable().optional(),
    }),
    workMode: WorkModeOptionsEnum.optional(),
    background: z.object({
      r: number(),
      g: number(),
      b: number(),
    }),
    magicMode: magicModeOptionsEnum,
    zoomType: ZoomTypeOptionsEnum,
    retouchPatternSize: number(),
    retouchPatternType: BrushTypeOptionsEnum,
    retouchPaintSize: number(),
    retouchPaintType: BrushTypeOptionsEnum,
    zoomPercent: number(),
    brushsize: number().default(1),
    rubbersize: number().default(1),
    brushtype: BrushTypeOptionsEnum,
    rubbertype: BrushTypeOptionsEnum,
    visibiltyToggle: boolean(),
    limitToMask: boolean(),
    colorChecker: z.object({
      status: z.number().transform((status) => status === 1),
      type: z.number(),
      height: z.number(),
      width: z.number(),
      x: z.number(),
      y: z.number(),
    }),
    undo: boolean(),
    redo: boolean(),
    magicTolerance: number(),
    magicCircumference: number(),
    objects: array(
      object({
        name: string(),
        height: number(),
        width: number(),
        material: object({
          matId: string().optional(),
          unique: string().optional(),
        }),
        layer: array(
          object({
            type: string(),
            booleanMode: BooleanModeOptionsEnum,
            name: string().optional().default(''),
            x: number().optional().default(0),
            y: number().optional().default(0),
            width: number().optional().default(0),
            height: number().optional().default(0),
            edit: boolean().optional().default(false),
            editMode: LayerEditModeEnum.optional().default('select'),
            cnt: number().optional().default(0),
            closed: boolean().optional().default(true),
            editPoint: z
              .object({
                id: number(),
                x: number(),
                y: number(),
              })
              .nullable()
              .optional()
              .default(null),
          }),
        ),
        grid: object({
          width: number(),
          height: number(),
          x: number(),
          y: number(),
        }),
        autoBrightness: number(),
        brightness: number(),
        contrast: number(),
        testPattern: boolean(),
        mode: ObjectModeOptionsEnum.optional().nullable().default('poly'),
      }),
    ),
  }),
}).transform((status) => ({
  activeComponentIndex: status.data.active.elementIdx,
  activeLayerIndex: status.data.active.layerIdx,
  workMode: status.data.workMode ?? 'selectPoly',
  zoomType: status.data.zoomType,
  zoomPercent: status.data.zoomPercent,
  brushSize: status.data.brushsize,
  brushType: status.data.brushtype,
  rubberSize: status.data.rubbersize,
  rubberType: status.data.rubbertype,
  retouchPatternSize: status.data.retouchPatternSize,
  retouchPatternType: status.data.retouchPatternType,
  retouchPaintSize: status.data.retouchPaintSize,
  retouchPaintType: status.data.retouchPaintType,
  background: status.data.background,
  magicMode: status.data.magicMode,
  magicWandTolerance: status.data.magicTolerance,
  magicWandCircumference: status.data.magicCircumference,
  limitToMask: status.data.limitToMask,
  isVisibilityEnabled: status.data.visibiltyToggle,
  colorChecker: status.data.colorChecker,
  canUndo: status.data.undo,
  canRedo: status.data.redo,
  components: status.data.objects.map((object, i) => ({
    grid: object.grid,
    height: object.height,
    width: object.width,
    layers: object.layer.map((layer, i) => ({
      type: layer.type,
      name: layer.name,
      index: i,
      booleanMode: layer.booleanMode,
      x: layer.x,
      y: layer.y,
      width: layer.width,
      height: layer.height,
      editMode: layer.editMode,
      cnt: layer.cnt,
      edit: layer.edit,
      editPoint: layer.editPoint,
      isClosed: layer.closed,
    })),
    name: object.name,
    mode: object.mode,
    materialId: object.material?.matId ?? null,
    index: i,
    autoBrightness: object.autoBrightness,
    brightness: object.brightness,
    contrast: object.contrast,
    showTestPattern: object.testPattern,
  })),
}));

export type EditorModuleStatus = z.infer<typeof EditorStatusSchema>;

export type EditorComponent = EditorModuleStatus['components'][0];

export type ComponentLayer = EditorComponent['layers'][0];
