import {
  Box,
  Button,
  Popover,
  PopoverAnchor,
  PopoverContent,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';

export interface HookFormDropdownInputProps<T extends FieldValues>
  extends HookFormInputProps<T> {
  dropdownOpen: boolean;
  dropdownContent: () => ReactNode;
  toggleDropdown: (open: boolean) => void;
}

export const HookFormDropdownInput = <T extends FieldValues>({
  accessor,
  label,
  registerOptions,
  description,
  dropdownOpen,
  toggleDropdown,
  dropdownContent,
  ...rest
}: HookFormDropdownInputProps<T>) => {
  const { t } = useTranslation();

  return (
    <Popover
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
      closeOnBlur={false}
      gutter={-3}
      isLazy
      isOpen={dropdownOpen}
      onClose={() => toggleDropdown(false)}
      onOpen={() => toggleDropdown(true)}
      placement='bottom-start'
      variant='responsive'
    >
      <PopoverAnchor>
        <Box zIndex={1600}>
          <HookFormInput<T>
            type='text'
            accessor={accessor}
            label={label}
            placeholder={t(
              'dashboard.authentication.changePassword.inputPassword',
            )}
            registerOptions={registerOptions}
            description={description}
            inputRightElement={
              <Button
                onClick={() => toggleDropdown(!dropdownOpen)}
                variant='text'
              >
                <Icon
                  icon={dropdownOpen ? 'cancel' : 'chevron_down'}
                  w='14px'
                  color='lighterText'
                />
              </Button>
            }
            {...rest}
          />
        </Box>
      </PopoverAnchor>

      <PopoverContent
        overflowY='auto'
        w='calc(100% - 80px)'
        maxH='300px'
        bg='background'
        borderRadius='0 0 4px 4px'
      >
        {dropdownContent()}
      </PopoverContent>
    </Popover>
  );
};
