import { Button, HStack, Text, Input, Box } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

interface Props {
  initialValue: number;
  onChange: (value: number) => void;
  text: string;
  minValue?: number;
  maxValue?: number;
  stepSize?: number;
}

const DELAY = 300;

export const EditorNumberInput: FC<Props> = ({
  onChange,
  text,
  initialValue: value,
  maxValue,
  minValue,
  stepSize = 1,
}) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      if (internalValue != value) {
        onChange(internalValue);
      }
    }, DELAY);

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    // with onChange as a dependency, it will cause a rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  const limitValue = (value: number) => {
    let newValue = value;
    if (minValue != null) {
      newValue = Math.max(minValue, newValue);
    }

    if (maxValue != null) {
      newValue = Math.min(maxValue, newValue);
    }

    return newValue;
  };

  return (
    <HStack justify='space-between' w='full'>
      <Text fontSize='sm'>{text}</Text>
      <HStack
        align='center'
        justify='space-between'
        w='6.625rem'
        h='8'
        px='2'
        bg='form.inputBackground'
        border='1px solid'
        borderColor='border'
        borderRadius='4px'
        divider={<Box h='20px' mx='2' borderColor='border' />}
      >
        <Button
          minW='0'
          px='0'
          color='text'
          fontWeight='normal'
          textAlign='center'
          onClick={() => setInternalValue(limitValue(internalValue - stepSize))}
          variant='text'
        >
          -
        </Button>
        <Input
          fontSize='md'
          textAlign='center'
          max={maxValue}
          min={minValue}
          onChange={(e) =>
            setInternalValue(limitValue(Number.parseInt(e.target.value)))
          }
          type='number'
          value={internalValue}
          variant='unstyled'
        />
        <Button
          minW='0'
          px='0'
          fontWeight='normal'
          textAlign='center'
          onClick={() => setInternalValue(limitValue(internalValue + stepSize))}
          variant='text'
        >
          +
        </Button>
      </HStack>
    </HStack>
  );
};
