import { useQuery } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';

export const useManagedBrandCountry = () => {
  const countries = useQuery(brandCountriesQuery);
  const user = useAuthenticationStore.useUser();

  const countryToUpdate = countries?.data?.response.payload.find(
    (country) => country.id === user?.managedCountries[0],
  );

  return countryToUpdate;
};
