import { createRoute } from '@tanstack/react-router';

import { AdminContactPage } from '$/pages/AdminPages/pages/ContactPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminContactRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/contact',
  component: AdminContactPage,
});
