import { useMutation } from '@tanstack/react-query';

import { queryClient } from '$/services/fetcher';
import {
  countryAdminsQuery,
  updateCountryAdmin,
} from '$/services/usecases/admin/countryAdmins';

export const useUpdateAdmin = (options: { onSuccess?: () => void } = {}) => {
  return useMutation({
    mutationFn: updateCountryAdmin,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: countryAdminsQuery.queryKey }),
    ...options,
  });
};
