import { queryOptions } from '@tanstack/react-query';

import { useLocalizationStore } from '$/components/core/Localization/hooks/useLocalizationStore';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { FilterType } from '$/services/mapper/uses';
import {
  FilterGroup,
  getFilterOptions,
  getUnfilteredProducers,
} from '$/services/usecases/filter';

export const filterOptionsQuery = ({
  type,
  filter,
  hasUser,
}: {
  type?: FilterType;
  filter?: FilterGroup[];
  hasUser?: boolean;
  language?: string;
} = {}) =>
  queryOptions({
    queryKey: [
      'filter_options',
      type,
      filter,
      hasUser,
      useLocalizationStore.getState().language,
    ],
    queryFn: ({ signal }) => getFilterOptions({ signal, type, filter }),
    staleTime: STANDARD_STALE_TIME,
  });

export const producerOptionsQuery = queryOptions({
  queryKey: ['producer'],
  queryFn: getUnfilteredProducers,
  staleTime: STANDARD_STALE_TIME,
});
