import { Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  languages: string[];
  onDelete: (index: number) => void;
}

export const SelectedLanguagesTags: FC<Props> = ({ languages, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Flex wrap='wrap' gap='1'>
      {languages.map((language, index) => (
        <HStack
          key={index}
          px='4'
          py='2'
          border='1px solid'
          borderColor='border'
          borderRadius='full'
        >
          <Icon icon='languages' w='18px' />
          <Text>{t(`languages.${language}`)}</Text>
          <IconButton
            minW='0'
            h='fit-content'
            p='0'
            color='lighterText'
            aria-label='remove'
            onClick={() => onDelete(index)}
            variant='ghost'
          >
            <Icon icon='close' w='12px' />
          </IconButton>
        </HStack>
      ))}
    </Flex>
  );
};
