import { Button, HStack, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { trackContentInteraction } from '$/logger';
import { LanguageOption } from '$/utils/piwikUtils';

interface Props {
  id: string;
  type: 'country' | 'language';
  onClick: (id: string) => void;
  isActive: boolean;
}

export const FlagButton: FC<Props> = ({ id, type, onClick, isActive }) => {
  const { t } = useTranslation();

  const label =
    type === 'country'
      ? t(`countries.${id}`, { defaultValue: id })
      : t(`languages.${id}`, { defaultValue: id });

  return (
    <Button
      key={id}
      w='fit-content'
      p='2'
      fontWeight='normal'
      border='2px solid'
      borderColor='transparent'
      transition='none'
      onClick={() => {
        trackContentInteraction(
          'ClickButton',
          'MainMenu',
          'ChooseLanguage',
          t(`languages.${id}`, {
            lng: 'en',
          }) as LanguageOption,
        );
        onClick(id);
      }}
      variant='ghost'
      {...(isActive && {
        color: 'selectedText',
        bg: 'primaryButton.lightBackground',
        borderColor: 'form.activeBorder',
        fontWeight: 'bold',
      })}
    >
      <HStack>
        {id === 'xw' ? (
          <Icon icon='globe' />
        ) : (
          <Image
            w='30px'
            borderRadius='2px'
            alt={label}
            src={`/icons/flags/${id}.svg`}
          />
        )}

        <Text fontSize='sm' textAlign='start'>
          {label}
        </Text>
      </HStack>
    </Button>
  );
};
