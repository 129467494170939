import { Stack } from '@chakra-ui/react';

import { BrandCountryList } from '$/pages/AdminPages/pages/CountriesPage/components/BrandCountryList';
import { CountryPageHeader } from '$/pages/AdminPages/pages/CountriesPage/components/CountryPageHeader';

export const AdminCountriesPage = () => {
  return (
    <Stack gap='4' overflowY='auto' w='full' p='8'>
      <CountryPageHeader />
      <BrandCountryList />
    </Stack>
  );
};
