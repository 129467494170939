import { Stack } from '@chakra-ui/react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CountryAdminLanguageList } from '$/pages/AdminPages/pages/LanguagesPage/components/CountryAdminLanguageList';
import { LanguageList } from '$/pages/AdminPages/pages/LanguagesPage/components/LanguageList';
import { LanguagesPageHeader } from '$/pages/AdminPages/pages/LanguagesPage/components/LanguagesPageHeader';

export const AdminLanguagesPage = () => {
  const role = useAuthenticationStore.useUserRole();

  return (
    <Stack gap='4' overflowY='auto' w='full' p='8'>
      <LanguagesPageHeader />
      {role === 'superAdmin' && <LanguageList />}
      {role === 'countryAdmin' && <CountryAdminLanguageList />}
    </Stack>
  );
};
