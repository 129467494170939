import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { NavbarLink } from '$/components/layouts/BaseDashboardLayout/components/NavbarLink';

export const AdminNavbar = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  return (
    <Stack
      layerStyle='DashboardSection'
      flex='1'
      gap='0'
      overflow='hidden'
      w={{ base: 'fit-content', md: '14rem' }}
      h='100%'
    >
      <Stack justify='space-between' gap='0' w='full' h='full'>
        <Stack gap='0'>
          {hasPermission('Admin_User_Management') && (
            <NavbarLink
              icon={<Icon icon='users' w='20px' />}
              title={t('admin.userManagement.navbarTitle')}
              to='/admin/user-management'
            />
          )}
          {hasPermission('Admin_Contact_Management') && (
            <NavbarLink
              icon={<Icon icon='message' w='20px' />}
              title={t('admin.contact.navbarTitle')}
              to='/admin/contact'
            />
          )}
          <NavbarLink
            icon={<Icon icon='lightbulb' w='24px' h='24px' />}
            title={t('admin.inspirations.navbarTitle')}
            to='/admin/inspirations'
          />
          {hasPermission('Admin_Producer_Management') && (
            <NavbarLink
              icon={<Icon icon='loyalty_tag' h='20px' />}
              title={t('admin.producers.navbarTitle')}
              to='/admin/producers'
            />
          )}
          <NavbarLink
            icon={<Icon icon='collection' h='20px' />}
            title={t('admin.collections.navbarTitle')}
            to='/admin/collections'
          />
          <NavbarLink
            icon={<Icon icon='languages' w='18px' />}
            title={t('admin.languages.navbarTitle')}
            to='/admin/languages'
          />
          {hasPermission('Admin_Country_Managemet') && (
            <NavbarLink
              icon={<Icon icon='country_globe' w='20px' />}
              title={t('admin.countries.navbarTitle')}
              to='/admin/countries'
            />
          )}
        </Stack>
        <Stack gap='0'>
          <SidebarDivider />
          <NavbarLink
            icon={
              <Icon icon='chevron_down' h='20px' transform='rotate(90deg)' />
            }
            title={t('dashboard.navbar.admin')}
            to='/'
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
