import { z } from 'zod';

import { backendEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const GeolocationSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.object({ isoCode: z.string().nullable(), ipAddress: z.string() }),
});

export const getGeolocation = async () => {
  return fetcher(backendEndpoints.GEOLOCATION_URL, {
    method: 'GET',
    parser: (data) => GeolocationSchema.parse(data),
  });
};
