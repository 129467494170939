import { createRoute } from '@tanstack/react-router';

import { AdminProducersPage } from '$/pages/AdminPages/pages/ProducersPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminProducersRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/producers',
  component: AdminProducersPage,
});
