import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { CountryAdminInspirationList } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/CountryAdminInspirationList';

export const CountryAdminInspirationPage = () => {
  const { t } = useTranslation();

  return (
    <Stack gap='4' overflowY='auto' w='full' p='8'>
      <GeneralAdminHeader
        title={t('admin.inspirations.title')}
        description={t('admin.inspirations.descriptionCountryAdmin')}
      />
      <CountryAdminInspirationList />
    </Stack>
  );
};
