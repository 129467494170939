import { Await, Link } from '@tanstack/react-router';
import { MouseEventHandler, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { DashboardRoute } from '$/routes/DashboardRoute';
import { repeat } from '$/utils/arrayUtils';

export const CollectionSection = () => {
  const { t } = useTranslation();

  const { materials } = DashboardRoute.useLoaderData();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const { hasPermission } = useAuthorization();
  const isDemoUser = !hasPermission('View_All_Collections');

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isDemoUser) {
      openAuthenticationModal('versionComparison');
      e.preventDefault();
    }
  };

  return (
    <SectionHeadline
      headline={t('dashboard.welcome.collectionsText')}
      showAllUrl='/collections'
    >
      <DashboardSectionRow>
        <Suspense
          fallback={repeat(4).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        >
          <Await promise={materials}>
            {({
              colorMaterial,
              floorMaterial,
              wallMaterial,
              facadeMaterial,
            }) => {
              return (
                <>
                  {colorMaterial && (
                    <Link to='/collections/colors' key={colorMaterial.id}>
                      <MaterialCard
                        key={colorMaterial.id}
                        material={colorMaterial}
                        labelOverwrite={t(
                          'dashboard.welcome.collection.colorTones',
                        )}
                        editMode='none'
                        cursor='pointer'
                      />
                    </Link>
                  )}
                  {wallMaterial && (
                    <Link
                      to='/collections/walls'
                      key={wallMaterial.id}
                      onClick={handleLinkClick}
                    >
                      <MaterialCard
                        key={wallMaterial.id}
                        material={wallMaterial}
                        labelOverwrite={t(
                          'dashboard.welcome.collection.wallCoverings',
                        )}
                        editMode='none'
                        cursor='pointer'
                      />
                    </Link>
                  )}
                  {floorMaterial && (
                    <Link
                      to='/collections/floors'
                      key={floorMaterial.id}
                      disabled={isDemoUser}
                      onClick={handleLinkClick}
                    >
                      <MaterialCard
                        material={floorMaterial}
                        key={floorMaterial.id}
                        labelOverwrite={t(
                          'dashboard.welcome.collection.floorCoverings',
                        )}
                        editMode='none'
                        cursor='pointer'
                      />
                    </Link>
                  )}
                  {facadeMaterial && (
                    <Link
                      to='/collections/facades'
                      key={facadeMaterial.id}
                      disabled={isDemoUser}
                      onClick={handleLinkClick}
                    >
                      <MaterialCard
                        key={facadeMaterial.id}
                        material={facadeMaterial}
                        labelOverwrite={t(
                          'dashboard.welcome.collection.facades',
                        )}
                        editMode='none'
                        cursor='pointer'
                      />
                    </Link>
                  )}
                </>
              );
            }}
          </Await>
        </Suspense>
      </DashboardSectionRow>
    </SectionHeadline>
  );
};
