import { createRoute, redirect } from '@tanstack/react-router';
import { memo } from 'react';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { InspirationEditorPage } from '$/pages/InspirationEditorPage';
import {
  Layer,
  useInspirationEditorStore,
} from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { RootRoute } from '$/routes/RootRoute';
import { queryClient } from '$/services/fetcher';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { inspirationProjectQuery } from '$/services/usecases/projects/queries';
import { completeSceneQuery } from '$/services/usecases/scenes/queries';
import { useCollectionStore, initial } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { useLastSeenStore } from '$/stores/useLastSeenStore';
import {
  hideCookiebotTrigger,
  showCookiebotTrigger,
} from '$/utils/cookiebotUtils';

const RouteComponent = memo(() => <InspirationEditorPage />);
RouteComponent.displayName = 'InspirationProjectPage';

export const InspirationsProjectRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/inspirationProjects/$id',
  component: RouteComponent,
  onLeave: () => {
    useCollectionStore.setState({
      filterGroups: initial.filterGroups,
      brightnessFilter: initial.brightnessFilter,
    });
    useInspirationEditorStore.setState({
      activeSceneVariantIndex: 0,
      sceneLayersVariants: [],
      activeViewIndex: 0,
      activeComponentId: null,
      viewIds: [],
    });
    useFavoriteStore.setState({ activeFolderId: null });
    showCookiebotTrigger();
  },
  onEnter: () => {
    hideCookiebotTrigger();
  },
  loader: ({ params, context: { client } }) => {
    useCollectionStore.setState({ filter: undefined });
    useMaterialSearchStore.setState({ query: '', dropdownVisible: false });
    void client.ensureQueryData(filterOptionsQuery()).then((filter) => {
      useCollectionStore.setState({ filter });
    });

    const loadComponents = async () => {
      const state = useInspirationEditorStore.getState();
      useCollectionStore.setState({ activeType: 'all' });

      state.setIsLoading(true);

      const response = await queryClient.fetchQuery(
        inspirationProjectQuery(params.id),
      );

      if (!response.isSuccessful || response.response?.payload == null) {
        if (response.httpStatusCode === 401) {
          redirect({ to: '/not-allowed', throw: true });
        }
        if (response.httpStatusCode === 404) {
          redirect({ to: '/not-found', throw: true });
        }
        return;
      }

      const scene = await client.ensureQueryData(
        completeSceneQuery(response.response.payload.sceneId),
      );

      if (!scene) {
        redirect({ to: '/not-found', throw: true });
        return null;
      }

      const allMaterials = [
        ...new Set(
          response.response.payload.variants
            .flat()
            .filter((v) => v.materialUniqueKey != null)
            .map((v) => v.materialUniqueKey),
        ),
      ] as string[];

      const projectMaterials = await client.ensureQueryData(
        materialsQuery({ ids: allMaterials }, false, true),
      );

      const components = scene.views.reduce<Layer[]>((acc, view) => {
        view.components.forEach((component) => {
          const index = acc.findIndex((c) => c.id === component.id);
          if (index === -1) {
            acc.push({ ...component, views: [view.viewNr] });
          } else {
            acc[index].views.push(view.viewNr);
          }
        });
        return acc;
      }, []);

      const variants = response.response.payload.variants.map((variant) => {
        return variant.map((layer) => {
          const component = components.find((c) => c.id === layer.objectId);
          if (!component) {
            throw new Error('Component not found');
          }
          const material = projectMaterials.materials.find(
            (m) =>
              m.uniqueKey.replace(/\s+/g, '_') ===
              layer.materialUniqueKey?.replace(/\s+/g, '_'),
          );

          return { ...component, material: material ?? null };
        });
      });

      if (state.sceneLayersVariants.length === 0) {
        state.setSceneLayersVariants(variants);
      }

      useLastSeenStore.getState().addLastScene(scene.id);
      useInspirationEditorStore.setState({
        isLoading: false,
        layerMarkers: components,
        completeScene: scene,
        scene: { ...scene, views: scene.views.map((_, i) => i.toString()) },
        currentProjectId: response.response.payload.id,
      });

      return useInspirationEditorStore.getState().getActiveSceneVariant();
    };

    void loadComponents();
  },
  staleTime: 0,
});
