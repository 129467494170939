import {
  Center,
  Spinner,
  Stack,
  Text,
  VStack,
  Button,
  Grid,
  Box,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { EditCollectionModal } from '$/pages/AdminPages/pages/CollectionsPage/pages/SuperAdminCollectionsPage/components/EditCollectionModal';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { Collection, collectionsQuery } from '$/services/usecases/collections';

export const SuperAdminCollectionsPage = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedCollection, setSelectedCollection] =
    useState<Collection | null>(null);
  const brandCountries = useQuery(brandCountriesQuery);
  const collections = useQuery(collectionsQuery);

  const onCloseModal = () => {
    onClose();
    setSelectedCollection(null);
  };

  if (brandCountries.isLoading || collections.isLoading) {
    return (
      <VStack alignItems='start' h='full' p='8'>
        <StyledScrollWrapper>
          <GeneralAdminHeader
            title={t('admin.collections.title')}
            description={t('admin.collections.superadminDescription')}
          />
          <Center>
            <Spinner />
          </Center>
        </StyledScrollWrapper>
      </VStack>
    );
  }

  return (
    <Stack alignItems='start' h='full' p='8'>
      <StyledScrollWrapper>
        <GeneralAdminHeader
          title={t('admin.collections.title')}
          description={t('admin.collections.superadminDescription')}
        />

        <Stack gap='4' mt='4'>
          {collections.data?.map((collection) => (
            <HStack
              key={collection.intName}
              px='6'
              py='4'
              border='1px solid'
              borderColor='border'
              borderRadius='8px'
            >
              <Grid
                alignItems='center'
                templateColumns='minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr)'
                w='full'
              >
                <Text fontWeight='bold'>{collection.showName}</Text>
                <Box>
                  <Text color='lighterText'>
                    {t('admin.collections.materialCount', {
                      count: collection.cnt,
                    })}
                  </Text>
                  <Text fontWeight='bold'>{collection.producerShowName}</Text>
                </Box>
                <Text>
                  {brandCountries.data?.response.payload
                    .filter((brandCountry) =>
                      brandCountry.collectionIds.includes(collection.intName),
                    )
                    .map((country) =>
                      t(`countries.${country.id}`, {
                        defaultValue: country.id,
                      }),
                    )
                    .join(', ')}
                </Text>
              </Grid>
              <Button
                minW='auto'
                px='6'
                leftIcon={<Icon icon='edit_pen' boxSize='3' />}
                onClick={() => {
                  setSelectedCollection(collection);
                  onOpen();
                }}
                variant='tertiary'
              >
                {t('admin.inspirations.editButton')}
              </Button>
            </HStack>
          ))}
        </Stack>
      </StyledScrollWrapper>
      <EditCollectionModal
        brandCountries={brandCountries.data?.response.payload ?? []}
        isOpen={isOpen}
        onClose={onCloseModal}
        collection={selectedCollection}
      />
    </Stack>
  );
};
