import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';
import { UserSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';

export const GetAllCountryAdminsSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.array(UserSchema),
});

export const CreateUpdateCountryAdminsSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: UserSchema.optional(),
});
