import { HStack, Button, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormDropdownInput,
  HookFormDropdownInputProps,
} from '$/components/core/Form/HookFormDropdownInput';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { useFilteredBrandCountries } from '$/pages/AdminPages/pages/UserPage/hooks/useFilteredBrandCountries';
import { queryClient } from '$/services/fetcher';
import {
  countryAdminsQuery,
  createCountryAdmin,
} from '$/services/usecases/admin/countryAdmins';

type AddCountryAdminData = {
  email: string;
  managedCountry: string;
};

const EmailInput = (props: HookFormInputProps<AddCountryAdminData>) => (
  <HookFormInput<AddCountryAdminData> {...props} />
);

const CountryInput = (
  props: HookFormDropdownInputProps<AddCountryAdminData>,
) => <HookFormDropdownInput<AddCountryAdminData> {...props} />;

interface Props {
  onCloseModal: () => void;
}

export const AddCountryAdminForm: FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation();
  const formMethods = useForm<AddCountryAdminData>();

  const [creationLoading, setCreationLoading] = useState(false);

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const query = formMethods.watch('managedCountry');
  const { filteredCountries, unfilteredCountries } =
    useFilteredBrandCountries(query);

  useEffect(() => {
    if (query?.length > 0) onOpen();
  }, [query, onOpen]);

  const onSubmit = async (data: AddCountryAdminData) => {
    if (!unfilteredCountries) return;

    setCreationLoading(true);

    const countryKey = unfilteredCountries.find(
      (country) =>
        t(`countries.${country.id}`, { defaultValue: country.id }) ===
        data.managedCountry,
    )!.id;

    const res = await createCountryAdmin(data.email, [countryKey]).finally(() =>
      setCreationLoading(false),
    );

    if (res.isSuccessful) {
      await queryClient.invalidateQueries({
        queryKey: countryAdminsQuery.queryKey,
      });

      return onCloseModal();
    }

    showToast(
      t('admin.userManagement.addUser.generalError'),
      'error',
      cloudMessageTranslation(res.response.message),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='6' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <EmailInput
          accessor='email'
          label={t('admin.userManagement.addUser.emailLabel')}
          placeholder={t('admin.userManagement.addUser.emailPlaceholder')}
          registerOptions={{
            required: t(
              'admin.userManagement.addUser.errorMessages.isRequired',
            ),
          }}
        />

        <Stack>
          <CountryInput
            dropdownContent={() => {
              return (
                <Stack pt='2' pb='6px'>
                  {filteredCountries?.length === 0 && (
                    <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                      {t('admin.userManagement.addUser.noResults')}
                    </Text>
                  )}
                  {filteredCountries?.map((country) => (
                    <Button
                      key={country.id}
                      px='4'
                      py='6px'
                      fontSize='sm'
                      fontWeight='normal'
                      textAlign='left'
                      _hover={{ bg: 'bodyBackground' }}
                      cursor='pointer'
                      onClick={() => {
                        formMethods.setValue(
                          'managedCountry',
                          t(`countries.${country.id}`, {
                            defaultValue: country.id,
                          }),
                        );
                        setTimeout(() => onClose(), 0);
                      }}
                      variant='unstyled'
                    >
                      {t(`countries.${country.id}`, {
                        defaultValue: country.id,
                      })}
                    </Button>
                  ))}
                </Stack>
              );
            }}
            dropdownOpen={isOpen}
            toggleDropdown={(open) => (open ? onOpen() : onClose())}
            accessor='managedCountry'
            label={t('admin.userManagement.addUser.countryLabel')}
            placeholder={t('admin.userManagement.addUser.countryPlaceholder')}
            registerOptions={{
              required: t(
                'admin.userManagement.addUser.errorMessages.isRequired',
              ),
              validate: {
                countryExists: (v) =>
                  unfilteredCountries?.some(
                    (country) =>
                      t(`countries.${country.id}`, {
                        defaultValue: country.id,
                      }) === v,
                  ) ||
                  t(
                    'admin.userManagement.addUser.errorMessages.invalidCountry',
                  ),
              },
            }}
          />
          <Text color='lighterText' fontSize='xs'>
            {t('admin.userManagement.addUser.responsibleCountries')}
          </Text>
        </Stack>

        <HStack justifyContent='space-between' w='full' mt='4'>
          <Button
            color='lighterText'
            fontSize='sm'
            onClick={onCloseModal}
            variant='ghost'
          >
            {t('admin.userManagement.addUser.cancel')}
          </Button>
          <Button
            px='6'
            py='3'
            fontSize='sm'
            isLoading={creationLoading}
            type='submit'
            variant='primary'
          >
            {t('admin.userManagement.addUser.addUser')}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
