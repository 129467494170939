import { createRoute } from '@tanstack/react-router';

import { AdminInspirationPage } from '$/pages/AdminPages/pages/InspirationsPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';
import { queryClient } from '$/services/fetcher';

export const AdminInspirationsRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/inspirations',
  component: AdminInspirationPage,
  onEnter: async () => {
    await queryClient.invalidateQueries({ queryKey: ['brandCountries'] });
  },
});
