import { useQuery } from '@tanstack/react-query';

import { useLocalizationStore } from '$/components/core/Localization/hooks/useLocalizationStore';
import { countriesQuery } from '$/services/usecases/internationalization/countries';

export const useActiveUserCountry = () => {
  const { data } = useQuery(countriesQuery);
  const country = useLocalizationStore.useCountry();
  return data?.response.payload.find((c) => c.id === country);
};
