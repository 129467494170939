import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';

interface Props {
  headerText: string;
  icon: AvailableIcons;
  descriptionText: string;
  buttonText: string;
}

export const MessagePage: FC<Props> = ({
  headerText,
  buttonText,
  descriptionText,
  icon,
}) => {
  return (
    <Center h='100dvh'>
      <VStack
        layerStyle='DashboardSection'
        gap='2.5rem'
        maxW='31rem'
        p='2.5rem'
      >
        <Heading fontSize='3xl' textAlign='center'>
          {headerText}
        </Heading>

        <Icon w='64px' h='64px' icon={icon} />
        <Text fontSize='sm'>{descriptionText}</Text>
        <Link to='/'>
          <Button variant='primary'>{buttonText}</Button>
        </Link>
      </VStack>
    </Center>
  );
};
