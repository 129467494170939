import { Box } from '@chakra-ui/react';
import { Draggable } from '@hello-pangea/dnd';
import { FC } from 'react';

import { CountryAdminInspirationCard } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/CountryAdminInspirationCard';
import { getNewYPosition } from '$/pages/EditorPage/utils/dndFencing';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { Scene } from '$/services/usecases/scenes';

interface Props {
  scene: Scene;
  position: number;
  dropableObject: HTMLElement | null;
  topOffset: number;
  height: number;
  countryToUpdate: BrandCountry;
  openModal: (scene: Scene) => void;
  isLoading?: boolean;
}

export const DraggableInspirationCard: FC<Props> = ({
  scene,
  position,
  dropableObject,
  topOffset,
  height,
  countryToUpdate,
  isLoading,
  openModal,
}) => {
  return (
    <Draggable
      draggableId={`scene-${scene.id}`}
      index={position}
      isDragDisabled={isLoading}
    >
      {(provided) => {
        if (
          provided.draggableProps.style?.transform != null &&
          dropableObject != null
        ) {
          const y = provided.draggableProps.style?.transform
            .split(',')[1]
            .replace('px)', '');

          provided.draggableProps.style.transform = `translate(0px, ${getNewYPosition(dropableObject, position, Number(y), height, topOffset)}px)`;
        }

        return (
          <Box
            ref={provided.innerRef}
            py='2'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CountryAdminInspirationCard
              key={scene.id}
              scene={scene}
              countryToUpdate={countryToUpdate}
              index={position}
              openModal={openModal}
            />
          </Box>
        );
      }}
    </Draggable>
  );
};
