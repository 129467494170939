import { Box, Stack } from '@chakra-ui/react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { useRef, MutableRefObject, useState, FC, useEffect } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { DraggableInspirationCard } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/DraggableInspirationCard';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { useUpdateBrandCountry } from '$/services/usecases/admin/mutations/useUpdateBrandCountry';
import { Scene } from '$/services/usecases/scenes';
import { reorderArray } from '$/utils/arrayUtils';

interface Props {
  scenes: Scene[];
  countryToUpdate: BrandCountry;
  openModal: (scene: Scene) => void;
}

export const DraggableInspirationCards: FC<Props> = ({
  scenes,
  countryToUpdate,
  openModal,
}) => {
  const user = useAuthenticationStore.useUser();

  const droppableParentRef = useRef<HTMLDivElement | null>(null);
  const droppableRef: MutableRefObject<HTMLElement | null> =
    useRef<HTMLDivElement | null>(null);

  const { mutate, isPending } = useUpdateBrandCountry();

  const [orderedScenes, setOrderedScenes] = useState<Scene[]>([]);

  useEffect(() => {
    if (scenes.length) setOrderedScenes(scenes);
  }, [scenes]);

  const onDragEnd = (result: DropResult) => {
    if (droppableRef.current) {
      droppableRef.current.style.borderColor = 'transparent';
    }
    if (!result.destination) {
      return;
    }

    const items = reorderArray(
      orderedScenes,
      result.source.index,
      result.destination.index,
    );

    if (!user || !user.managedCountries.length) return;

    mutate({
      brandCountry: { inspirationIds: items.map((item) => item.id) },
      id: user?.managedCountries[0],
    });

    setOrderedScenes(items);
  };

  return (
    <Stack ref={droppableParentRef} pb='122px'>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => {
          if (droppableRef.current) {
            droppableRef.current.style.borderColor = '#3059B6';
          }
        }}
      >
        <Droppable droppableId='scene-droppable'>
          {(provided) => (
            <Box
              ref={(ref) => {
                provided.innerRef(ref);
                droppableRef.current = ref;
              }}
              border='2px solid'
              borderColor='transparent'
              borderRadius='8px'
            >
              {orderedScenes.map((scene, i) => (
                <DraggableInspirationCard
                  key={scene.id}
                  scene={scene}
                  position={i}
                  dropableObject={droppableRef.current}
                  topOffset={droppableParentRef.current?.offsetTop ?? 0}
                  height={droppableParentRef.current?.offsetHeight ?? 0}
                  countryToUpdate={countryToUpdate}
                  openModal={openModal}
                  isLoading={isPending}
                />
              ))}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};
