import { useQuery } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  UserRole,
  userRoleDefinition,
} from '$/services/usecases/authentication/mapper/jwt';
import { projectsQuery } from '$/services/usecases/projects/queries';

const loggedInRoles = ['pro', 'countryAdmin', 'superAdmin'];
const adminRoles = ['countryAdmin', 'superAdmin'];

const permissionMatrix = {
  General_Login_Register: ['free'],
  General_User_Profile: [...loggedInRoles],
  General_Project_Page: [...loggedInRoles],
  General_Abo_Page: ['free'],
  General_Welcome_Project_Count: ['community', 'standard'],
  Favorites_Open: [...loggedInRoles],
  Favorites_Select: [...loggedInRoles],
  Project_Create: [...loggedInRoles],
  Project_Open: [...loggedInRoles],
  Project_Share: [...loggedInRoles],
  Project_Save: [...loggedInRoles],
  Editor_Use_Texture_Categories: [...loggedInRoles],
  View_All_Collections: [...loggedInRoles],
  Use_Pro_Materials: ['pro', ...adminRoles],
  Admin_Access: [...adminRoles],
  Admin_User_Management: ['superAdmin'],
  Admin_Country_Managemet: ['superAdmin'],
  Admin_Producer_Management: ['countryAdmin'],
  Admin_Contact_Management: ['countryAdmin'],
};

export type Permission = keyof typeof permissionMatrix;

export const hasPermissionFactory =
  (userRole: UserRole) => (requestedPermission: Permission) => {
    const allowedRoles = permissionMatrix[requestedPermission] ?? [];
    return allowedRoles.includes(userRole);
  };

const getProjectCountLimit = (userRole: UserRole) => () => {
  return userRoleDefinition[userRole].projectLimit;
};

const canCreateMoreProjects =
  (userRole: UserRole, userProjectCount: number) => () => {
    return getProjectCountLimit(userRole)() > userProjectCount;
  };

export const useAuthorization = () => {
  const user = useAuthenticationStore.useUser();
  const { data } = useQuery(projectsQuery({ enabled: user != null }));

  const role = user?.role ?? 'free';

  const projectCount = data?.response.payload.length ?? 0;

  return {
    hasPermission: hasPermissionFactory(role),
    canCreateMoreProjects: canCreateMoreProjects(role, projectCount),
    getProjectCountLimit: getProjectCountLimit(role),
  };
};
