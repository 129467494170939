import { useMutation } from '@tanstack/react-query';

import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { adminProducerQuery } from '$/services/usecases/admin/producers';

export const useUpdateBrandCountry = () => {
  return useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: adminProducerQuery(data?.response.payload.id ?? '').queryKey,
      });
    },
  });
};
