import {
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
} from '@chakra-ui/react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AddBrandCountryData } from '$/pages/AdminPages/pages/CountriesPage/components/AddBrandCountryModal/components/AddBrandCountryForm';

interface Props {
  form: UseFormReturn<AddBrandCountryData>;
}

export const CountryBrandMenu: FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const isBrand = form.watch('isBrand');

  return (
    <HStack pos='absolute' right='0' bottom='0' gap='0'>
      <Divider
        h='20px'
        my='0 !important'
        border='1px solid'
        borderColor='border'
        orientation='vertical'
      />
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              minW='100px'
              fontSize='sm'
              fontWeight='normal'
              rightIcon={
                <Icon
                  w='12px'
                  transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
                  transition='transform 0.3s ease'
                  icon='chevron_down'
                />
              }
              variant='ghost'
            >
              {isBrand
                ? t('admin.countries.addCountryBrand.brand')
                : t('admin.countries.addCountryBrand.country')}
            </MenuButton>
            <MenuList zIndex={1601} minW='0'>
              <MenuItem onClick={() => form.setValue('isBrand', false)}>
                {t('admin.countries.addCountryBrand.country')}
              </MenuItem>
              <MenuItem onClick={() => form.setValue('isBrand', true)}>
                {t('admin.countries.addCountryBrand.brand')}
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </HStack>
  );
};
