import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { ReadOnlyFolderCard } from '$/components/core/Collection/Favorites/ReadOnlyFolderCard';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { FullscreenSectionRow } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenSectionRow';
import { AuthActionCard } from '$/components/core/Projects/components/Cards/AuthActionCard';
import {
  DEFAULTFOLDERNAME,
  FavoriteFolder,
  useFavoriteStore,
} from '$/stores/useFavoriteStore';

interface Props {
  onShowMoreClick?: () => void;
  onFolderClick?: (folder: FavoriteFolder) => void;
  cardLimit?: number;
  label?: string;
  query?: string;
  includeDefaultFolder?: boolean;
}

export const ReadOnlyFavoriteFolderRow: FC<Props> = ({
  onShowMoreClick,
  onFolderClick,
  cardLimit,
  label,
  query,
  includeDefaultFolder = true,
}) => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();
  const folders = useFavoriteStore.useFavoriteFolders();
  const favorites = useFavoriteStore.useFavorites();

  const defaultFolder: FavoriteFolder = {
    id: DEFAULTFOLDERNAME,
    name: t('favorites.generalStorage'),
    isGlobalFavoriteFolder: false,
  };

  const folderItems = (
    includeDefaultFolder ? [defaultFolder, ...folders] : folders
  )
    .filter((folder) =>
      query ? folder.name.toLowerCase().includes(query.toLowerCase()) : true,
    )
    .filter((folder) =>
      favorites.some((favorite) =>
        favorite.parentFolderIds.includes(folder.id),
      ),
    );

  return (
    <SectionHeadline
      headline={label ?? t('general_editor.favorites')}
      onShowMoreClick={
        folderItems.length > 4 && cardLimit ? onShowMoreClick : undefined
      }
    >
      {hasPermission('Favorites_Open') && (
        <FullscreenSectionRow>
          {folderItems.slice(0, cardLimit).map((folder) => (
            <ReadOnlyFolderCard
              folder={folder}
              key={folder.id}
              onClick={() => onFolderClick?.(folder)}
            />
          ))}
        </FullscreenSectionRow>
      )}
      {!hasPermission('Favorites_Open') && (
        <FullscreenSectionRow>
          <AuthActionCard type='createFavorite' />
        </FullscreenSectionRow>
      )}
    </SectionHeadline>
  );
};
