import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  popper: {
    zIndex: 'popover',
  },
  content: {
    border: '1px solid',
    borderColor: 'border',
  },
});

export const Popover = defineMultiStyleConfig({
  baseStyle,
  variants: {
    responsive: {
      popper: {
        minWidth: 'unset !important',
        width: '100%',
      },
    },
  },
});
