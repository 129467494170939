import { queryOptions } from '@tanstack/react-query';

import { backendEndpoints } from '$/services/endpoints';
import { fetcher, STANDARD_STALE_TIME } from '$/services/fetcher';
import { GetAllCountriesSchema } from '$/services/usecases/internationalization/mapper/countries';

export const getAllCountries = async () => {
  return fetcher(backendEndpoints.COUNTRIES_URL, {
    method: 'GET',
    parser: (data) => GetAllCountriesSchema.parse(data),
  });
};

export const countriesQuery = queryOptions({
  queryKey: ['countries'],
  queryFn: () => getAllCountries(),
  staleTime: STANDARD_STALE_TIME,
});
