import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { FavoriteFolderView } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderView';
import { SearchResult } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult';
import { BrushSidebar } from '$/pages/EditorPage/components/AttributesSection/components/BrushSidebar';
import { ColorSelectionSidebar } from '$/pages/EditorPage/components/AttributesSection/components/ColorSelectionSidebar';
import { GridSidebar } from '$/pages/EditorPage/components/AttributesSection/components/GridSidebar';
import { LayerSidebar } from '$/pages/EditorPage/components/AttributesSection/components/LayerSidebar';
import { MagicWandSidebar } from '$/pages/EditorPage/components/AttributesSection/components/MagicWandSidebar';
import { OverviewSidebar } from '$/pages/EditorPage/components/AttributesSection/components/OverviewSidebar';
import { PathSidebar } from '$/pages/EditorPage/components/AttributesSection/components/PathSidebar';
import { RetouchColorSidebar } from '$/pages/EditorPage/components/AttributesSection/components/RetouchColorSidebar';
import { RubberSidebar } from '$/pages/EditorPage/components/AttributesSection/components/RubberSidebar';
import { useActiveEditorMaterial } from '$/pages/EditorPage/hooks/useActiveEditorMaterial';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { Material } from '$/services/usecases/materials';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

const FavoriteContent = () => {
  const { setMaterial } = useEditorActions();
  const material = useActiveEditorMaterial();

  const activeFolderId = useFavoriteStore.useActiveFolderId();

  if (!activeFolderId) return null;

  return (
    <FavoriteFolderView
      activeMaterialId={material?.id}
      onClickItem={(material) => setMaterial(material.id)}
      materialTypes={['facade', 'floor', 'wall', 'uni']}
    />
  );
};

const SearchResultContent = () => {
  const { setMaterial } = useEditorActions();

  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const query = useMaterialSearchStore.useQuery();
  const setMaterialState = useEditorStore.useSetMaterial();

  if (query.length < 3) return null;

  const handleResultClick = (material: Material) => {
    setMaterial(material.id);
    if (activeComponentId != null) {
      setMaterialState(activeComponentId, material.id);
    }
    useMaterialSearchStore.getState().setQuery('');
  };

  return <SearchResult onHandleResultClick={handleResultClick} />;
};

const ColorSelectionContent = () => {
  const activeFolderId = useFavoriteStore.useActiveFolderId();

  const { searchVisible } = useMaterialSearchStore(
    useShallow((s) => ({ searchVisible: s.query.length >= 3 })),
  );

  useEffect(() => {
    useCollectionStore.setState({ activeType: 'uni' });
  }, []);

  if (activeFolderId || searchVisible) return null;

  return <ColorSelectionSidebar />;
};

export const AttributeSidebarContent = () => {
  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const activeLayerId = useEditorStore.useActiveLayerIndex();
  const components = useEditorStore.useComponents();
  const workMode = useEditorStore.useWorkMode();

  if (['retouchPaint', 'retouchPattern', 'retouchGradient'].includes(workMode))
    return <RetouchColorSidebar />;

  if (activeComponentId == null) return <OverviewSidebar />;

  if (workMode === 'brush') return <BrushSidebar />;
  if (workMode === 'rubber') return <RubberSidebar />;
  if (workMode === 'magicWand') return <MagicWandSidebar />;

  if (workMode === 'grid') return <GridSidebar />;

  if (activeLayerId != null) {
    const activeLayer = components[activeComponentId].layers[activeLayerId];
    if (activeLayer.edit) {
      return <PathSidebar />;
    }

    return <LayerSidebar />;
  }

  return (
    <>
      <FavoriteContent />
      <SearchResultContent />
      <ColorSelectionContent />
    </>
  );
};
