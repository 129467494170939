import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';

type State = {
  isEditModalOpen: boolean;
  activeEditBrandCountry: BrandCountry | null;
};

type Action = {
  onEditModalOpen: (brandCountry: BrandCountry) => void;
  onEditModalClose: () => void;
};

const initial: State = {
  isEditModalOpen: false,
  activeEditBrandCountry: null,
};

export const store = create<State & Action>()((set) => ({
  ...initial,
  onEditModalClose: () => set({ isEditModalOpen: false }),
  onEditModalOpen: (activeEditBrandCountry) =>
    set({ isEditModalOpen: true, activeEditBrandCountry }),
}));

export const useBrandCountryStore = createSelectorHooks(store);
