import { VStack, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BrushOptions } from '$/pages/EditorPage/components/AttributesSection/components/BrushOptions';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import {
  Tip,
  TipSection,
} from '$/pages/EditorPage/components/AttributesSection/components/TipSection';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import {
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const BrushSidebar = () => {
  const { t } = useTranslation();

  const brushSize = useEditorStore.useBrushSize();
  const brushType = useEditorStore.useBrushType();

  const { setBrushSize } = useEditorActions();

  const tips: Tip[] = [
    {
      tipHeader: t('editor.tips.brushUseCase.title'),
      tipContent: t('editor.tips.brushUseCase.content'),
    },
    {
      tipHeader: t('editor.tips.drawStraightLine.title'),
      tipContent: t('editor.tips.drawStraightLine.content'),
    },
    {
      tipHeader: t('editor.tips.brushQuickHelp.title'),
      tipContent: t('editor.tips.brushQuickHelp.content'),
    },
  ];

  return (
    <VStack w='full' pb='4' fontSize='sm'>
      <TitleSection title={t('editor.brush.title')} />
      <SidebarDivider />
      <CurrentColorSection />
      <VisualizationSection />
      <SidebarDivider />
      <BrushOptions
        sizeAction={ModuleSendBrushSizeAction.BrushSize}
        shapeAction={ModuleSendBrushShapeAction.BrushShape}
        brushSize={brushSize}
        setBrushSize={setBrushSize}
        brushType={brushType}
      />
      <SidebarDivider />
      <VisibilityToggle />
      <Box mx='4' mb='1'>
        <TipSection tips={tips} />
      </Box>
    </VStack>
  );
};
