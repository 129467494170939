import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CountryAdminCollectionsPage } from '$/pages/AdminPages/pages/CollectionsPage/pages/CountryAdminCollectionsPage';
import { SuperAdminCollectionsPage } from '$/pages/AdminPages/pages/CollectionsPage/pages/SuperAdminCollectionsPage';

export const AdminCollectionsPage = () => {
  const userRole = useAuthenticationStore.useUserRole();

  if (userRole === 'superAdmin') {
    return <SuperAdminCollectionsPage />;
  }

  return <CountryAdminCollectionsPage />;
};
