import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AddLanguageForm } from '$/pages/AdminPages/pages/LanguagesPage/components/AddLanguageModal/components/AddLanguageForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddLanguageModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' minW='500px' p='10'>
        <ModalHeader p='0'>
          <Button
            px='0'
            color='lighterText'
            fontSize='sm'
            leftIcon={
              <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
            }
            onClick={onClose}
            variant='ghost'
          >
            {t('admin.languages.backToLanguageSection')}
          </Button>

          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7'>
            {t('admin.languages.addLanguage.addLanguage')}
          </Text>
        </ModalHeader>

        <ModalBody p='0'>
          <AddLanguageForm onCloseModal={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
