import { Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  title: string;
  description: string;
}

export const GeneralAdminHeader: FC<Props> = ({ title, description }) => {
  return (
    <Stack>
      <Text fontSize='h7' fontWeight='bold'>
        {title}
      </Text>
      <Text color='lighterText' fontSize='sm'>
        {description}
      </Text>
    </Stack>
  );
};
