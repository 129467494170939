import { createRoute } from '@tanstack/react-router';

import { AdminCountriesPage } from '$/pages/AdminPages/pages/CountriesPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminCountriesRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/countries',
  component: AdminCountriesPage,
});
