import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { DeleteCountryAdminForm } from '$/pages/AdminPages/pages/UserPage/components/EditCountryAdminModal/components/DeleteCountryAdminForm';
import { EditCountryAdminForm } from '$/pages/AdminPages/pages/UserPage/components/EditCountryAdminModal/components/EditCountryAdminForm';
import { useAdminUserStore } from '$/pages/AdminPages/pages/UserPage/stores/useAdminUserStore';

export const EditCountryAdminModal = () => {
  const { t } = useTranslation();

  const modalType = useAdminUserStore.useEditModalType();
  const setModalType = useAdminUserStore.useSetEditModalType();
  const isOpen = useAdminUserStore.useIsEditModalOpen();
  const onClose = useAdminUserStore.useOnEditModalClose();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' minW='500px' p='10'>
        <ModalHeader p='0'>
          <Button
            px='0'
            color='lighterText'
            fontSize='sm'
            leftIcon={
              <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
            }
            onClick={() => {
              modalType === 'update_admin'
                ? onClose()
                : setModalType('update_admin');
            }}
            variant='ghost'
          >
            {t('admin.userManagement.backToUserSection')}
          </Button>

          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7'>
            {modalType === 'update_admin'
              ? t('admin.userManagement.editUser.editUser')
              : t('admin.userManagement.editUser.delete')}
          </Text>
        </ModalHeader>

        <ModalBody p='0'>
          {modalType === 'update_admin' && <EditCountryAdminForm />}
          {modalType === 'delete_admin' && <DeleteCountryAdminForm />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
