import { createRoute } from '@tanstack/react-router';

import { AdminCollectionsPage } from '$/pages/AdminPages/pages/CollectionsPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminCollectionsRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/collections',
  component: AdminCollectionsPage,
});
