import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const CountrySchema = z.object({
  id: z.string(),
  isBrand: z.boolean(),
  languages: z.array(z.string()),
  contactEmail: z.string(),
});

export type Country = z.infer<typeof CountrySchema>;

export const GetAllCountriesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.array(CountrySchema),
});
