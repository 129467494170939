import { HStack, Button, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormDropdownInput,
  HookFormDropdownInputProps,
} from '$/components/core/Form/HookFormDropdownInput';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { useFilteredAvailableLanguageCodes } from '$/pages/AdminPages/pages/LanguagesPage/components/AddLanguageModal/hooks/useFilteredAvailableLanguageCodes';
import { queryClient } from '$/services/fetcher';
import {
  adminLanguagesQuery,
  createLanguage,
} from '$/services/usecases/admin/languages';

type AddLanguageData = {
  identifier: string;
};

const DropdownInput = (props: HookFormDropdownInputProps<AddLanguageData>) => (
  <HookFormDropdownInput<AddLanguageData> {...props} />
);

interface Props {
  onCloseModal: () => void;
}

export const AddLanguageForm: FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation();

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<AddLanguageData>();

  const { mutate, isPending } = useMutation({
    mutationFn: createLanguage,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: adminLanguagesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (res.isSuccessful) {
        onCloseModal();
        return;
      }

      showToast(
        t('admin.userManagement.addUser.generalError'),
        'error',
        cloudMessageTranslation(res.response.message),
      );
    },
  });

  const query = formMethods.watch('identifier');
  const { filteredLanguageCodes, unfilteredLanguageCodes, existingLanguages } =
    useFilteredAvailableLanguageCodes(query);

  useEffect(() => {
    if (query?.length > 0) onOpen();
  }, [query, onOpen]);

  const onSubmit = (data: AddLanguageData) => {
    mutate(data.identifier);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='6' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack>
          <DropdownInput
            dropdownContent={() => {
              return (
                <Stack pt='2' pb='6px'>
                  {filteredLanguageCodes?.length === 0 && (
                    <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                      {t('admin.languages.addLanguage.noResults')}
                    </Text>
                  )}
                  {filteredLanguageCodes?.map((code) => (
                    <Button
                      key={code}
                      px='4'
                      py='6px'
                      fontSize='sm'
                      fontWeight='normal'
                      textAlign='left'
                      _hover={{ bg: 'bodyBackground' }}
                      cursor='pointer'
                      onClick={() => {
                        formMethods.setValue('identifier', code);
                        setTimeout(() => onClose(), 0);
                      }}
                      variant='unstyled'
                    >
                      <HStack>
                        <Text>{code}</Text>
                        <Text color='lighterText' fontSize='xs'>
                          ({t(`languages.${code}`)})
                        </Text>
                      </HStack>
                    </Button>
                  ))}
                </Stack>
              );
            }}
            dropdownOpen={isOpen}
            toggleDropdown={(open) => (open ? onOpen() : onClose())}
            accessor='identifier'
            label={t('admin.languages.addLanguage.identifierLabel')}
            placeholder={t('admin.languages.addLanguage.identifierPlaceholder')}
            registerOptions={{
              required: t(
                'admin.languages.addLanguage.errorMessages.isRequired',
              ),
              validate: {
                countryExists: (v) =>
                  unfilteredLanguageCodes?.some(
                    (code) => code === v && !existingLanguages?.includes(code),
                  ) ||
                  t(
                    'admin.languages.addLanguage.errorMessages.invalidIdentifier',
                  ),
              },
            }}
          />
          <Text color='lighterText' fontSize='xs'>
            {t('admin.languages.addLanguage.choseIdentifierForLanguage')}
          </Text>
        </Stack>

        <HStack justifyContent='space-between' w='full' mt='4'>
          <Button
            color='lighterText'
            fontSize='sm'
            onClick={onCloseModal}
            variant='ghost'
          >
            {t('general.cancel')}
          </Button>
          <Button
            px='6'
            py='3'
            fontSize='sm'
            isLoading={isPending}
            type='submit'
            variant='primary'
          >
            {t('admin.languages.addLanguage.addLanguage')}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
