import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type State = {
  hasClosedTurnScreenModal: boolean;
};

export const initial: State = {
  hasClosedTurnScreenModal: false,
};

export const store = create<State>()(
  persist(() => ({ ...initial }), {
    name: 'general-store',
    partialize: (state) => ({
      hasClosedTurnScreenModal: state.hasClosedTurnScreenModal,
    }),
  }),
);

export const useGeneralStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State>;
