import { Container, Grid, GridItem } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-router';
import { FunctionComponent } from 'react';

import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { FeedbackModal } from '$/components/core/FeedbackModal';
import { ProfileModal } from '$/components/core/Profile/ProfileModal';
import { Header } from '$/components/layouts/BaseDashboardLayout/components/Header';
import { MobileHeader } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader';
import { Navbar } from '$/components/layouts/BaseDashboardLayout/components/Navbar';

export const BaseDashboardLayout: FunctionComponent = () => {
  return (
    <Container
      maxW={{ base: 'container.2xl', lg: '100%' }}
      h='100dvh'
      p={{ base: '16px', mobile: '32px', xl: '64px' }}
    >
      <Grid
        gap={{ base: '16px', xl: '32px' }}
        templateRows='auto minmax(0, 1fr)'
        templateColumns='auto 1fr'
        h='100%'
      >
        <GridItem display={{ base: 'none', mobile: 'flex' }} colSpan={2}>
          <Header />
        </GridItem>
        <GridItem display={{ base: 'flex', mobile: 'none' }} colSpan={2}>
          <MobileHeader />
        </GridItem>
        <GridItem display={{ base: 'none', mobile: 'flex' }}>
          <Navbar />
        </GridItem>
        <GridItem display='flex' minW='0' colSpan={{ base: 2, mobile: 'auto' }}>
          <Outlet />
        </GridItem>
      </Grid>
      <AuthenticationModal />
      <ProfileModal />
      <FeedbackModal />
    </Container>
  );
};
