import {
  FormControl,
  FormControlProps,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';
import get from 'lodash/get';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

export interface HookFormSwitchProps<T extends FieldValues>
  extends SwitchProps {
  accessor: FieldPath<T>;
  label?: string;
  registerOptions?: RegisterOptions<T>;
  formControlProps?: FormControlProps;
}

export const HookFormSwitch = <T extends FieldValues>({
  accessor,
  registerOptions,
  formControlProps,
  ...switchProps
}: HookFormSwitchProps<T>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <FormControl
      borderColor='form.inputBorder'
      isInvalid={get(errors, accessor) != null}
      {...formControlProps}
    >
      <Switch {...register(accessor, registerOptions)} {...switchProps} />
    </FormControl>
  );
};
