import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';

export const useFilteredBrandCountries = (query: string) => {
  const { t } = useTranslation();
  const { data } = useQuery(brandCountriesQuery);

  if (!query || query === '')
    return {
      filteredCountries: data?.response.payload,
      unfilteredCountries: data?.response.payload,
    };

  if (data?.response.payload) {
    const filteredCountries = data?.response.payload.filter((country) => {
      const name = t(`countries.${country.id}`).toLowerCase();
      return (
        name.includes(query.toLowerCase()) ||
        country.id.includes(query.toLocaleLowerCase())
      );
    });

    return {
      filteredCountries,
      unfilteredCountries: data?.response.payload,
    };
  }

  return {
    filteredCountries: [],
    unfilteredCountries: [],
  };
};
