import { Box, BoxProps, IconButton, Image } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { endpointFactory } from '$/services/endpoints';

interface Props extends BoxProps {
  sceneId: string;
  view: number;
  onClick: () => void;
}

export const ResizeableImage: FC<Props> = ({
  onClick,
  sceneId,
  view,
  ...boxProps
}) => {
  return (
    <Box pos='relative' {...boxProps}>
      <Image
        w='full'
        h='full'
        borderRadius='12px'
        objectFit='cover'
        src={endpointFactory.sceneImageUrl({
          id: sceneId,
          view: view,
        })}
      />
      <IconButton
        pos='absolute'
        top='2'
        right='2'
        minW='0'
        h='auto'
        minH='0'
        p='1'
        aria-label=''
        icon={<Icon icon='zoom_out_map' boxSize='4' />}
        onClick={onClick}
        variant='secondary'
      />
    </Box>
  );
};
