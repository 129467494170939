import {
  Button,
  Grid,
  HStack,
  Image,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAdminUserStore } from '$/pages/AdminPages/pages/UserPage/stores/useAdminUserStore';
import { useUpdateAdmin } from '$/services/usecases/admin/mutations/useUpdateAdmin';
import { User } from '$/services/usecases/authentication/mapper/genericCloudResponse';

interface Props {
  user: User;
}

export const CountryAdminCard: FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(user.isActiveAdmin);

  const onModalOpen = useAdminUserStore.useOnEditModalOpen();
  const { mutate, variables, isPending } = useUpdateAdmin();

  const toggleAdmin = () => {
    const isActiveAdmin =
      variables?.isActiveAdmin !== undefined
        ? !variables.isActiveAdmin
        : !user.isActiveAdmin;

    mutate({ id: user.id, isActiveAdmin });
    setIsChecked(isActiveAdmin);
  };

  useEffect(() => {
    setIsChecked(user.isActiveAdmin);
  }, [user.isActiveAdmin]);

  return (
    <HStack
      gap='0'
      px='8'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='md'
    >
      <Icon icon='account' boxSize='28px' />

      <Grid
        templateColumns='minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)'
        w='full'
        px='6'
      >
        <Stack gap='0'>
          <Text color='lighterText' fontSize='md'>
            {user.email}
          </Text>
          <Text fontSize='md' fontWeight='bold'>
            {user.firstname} {user.lastname}
          </Text>
        </Stack>

        <HStack w='240px'>
          {user.managedCountries[0] === 'xw' ? (
            <Icon icon='globe' />
          ) : (
            <Image
              w='30px'
              border='1px solid'
              borderColor='border'
              borderRadius='2px'
              alt={t(`countries.${user.managedCountries[0]}`)}
              src={`/icons/flags/${user.managedCountries[0]}.svg`}
            />
          )}

          <Text fontSize='md'>
            {t(`countries.${user.managedCountries[0]}`)}
          </Text>
        </HStack>

        <Stack gap='0'>
          <Text color='lighterText' fontSize='sm'>
            {t('admin.userManagement.lastActivity')}
          </Text>
          <Text fontSize='md'>
            {user.lastActivity && dayjs(user.lastActivity).format('DD.MM.YYYY')}
            {!user.lastActivity && ' - '}
          </Text>
        </Stack>

        <HStack>
          <Switch
            isChecked={isChecked}
            isDisabled={isPending}
            onChange={toggleAdmin}
          />
          <Text> {t('admin.userManagement.admin')}</Text>
        </HStack>
      </Grid>

      <Button
        px='6'
        fontSize='sm'
        leftIcon={<Icon icon='edit_pen' w='14px' />}
        onClick={() => onModalOpen(user)}
        variant='tertiary'
      >
        {t('admin.userManagement.edit')}
      </Button>
    </HStack>
  );
};
