import { Box, Button, HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

import { Icon } from '$/components/common/Icon';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';

export interface HookFormEditInputProps<T extends FieldValues>
  extends HookFormInputProps<T> {
  isLoading?: boolean;
  value: PathValue<T, Path<T>>;
  onSave: (val: string) => Promise<void>;
}

export const HookFormEditInput = <T extends FieldValues>({
  accessor,
  registerOptions,
  label,
  description,
  isLoading,
  value,
  onSave,
  ...rest
}: HookFormEditInputProps<T>) => {
  const [inEditMode, setInEditMode] = useState(false);
  const formMethods = useFormContext<T>();

  return (
    <HookFormInput<T>
      accessor={accessor}
      ignoreBlur={inEditMode}
      showDefaultBorder={!inEditMode}
      isReadOnly={!inEditMode}
      registerOptions={registerOptions}
      label={label}
      description={description}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          void onSave(formMethods.getValues()[accessor]).finally(() =>
            setInEditMode(false),
          );
        }
      }}
      inputRightElement={
        <HStack gap='0'>
          {!isLoading && !inEditMode && (
            <Button
              px='0'
              isLoading={isLoading}
              onClick={() => {
                setInEditMode(true);
                formMethods.setFocus(accessor);
                formMethods.setValue(accessor, value);
              }}
              variant='text'
            >
              <Icon icon='edit_pen' />
            </Button>
          )}

          {inEditMode && (
            <Box>
              {!isLoading && (
                <Button
                  px='0'
                  isLoading={isLoading}
                  onClick={() => {
                    setInEditMode(false);
                    formMethods.setValue(accessor, value);
                  }}
                  variant='text'
                >
                  <Icon icon='cancel' />
                </Button>
              )}
              <Button
                px='0'
                isLoading={isLoading}
                onClick={() =>
                  onSave(formMethods.getValues()[accessor]).finally(() =>
                    setInEditMode(false),
                  )
                }
                variant='text'
              >
                <Icon icon='submit' />
              </Button>
            </Box>
          )}
        </HStack>
      }
      {...rest}
    />
  );
};
