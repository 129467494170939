import {
  Button,
  Grid,
  GridProps,
  HStack,
  Image,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { endpointFactory } from '$/services/endpoints';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { Scene } from '$/services/usecases/scenes';

interface Props extends GridProps {
  scene: Scene;
  index: number;
  countryToUpdate: BrandCountry;
  openModal: (scene: Scene) => void;
}

export const CountryAdminInspirationCard: FC<Props> = ({
  scene,
  countryToUpdate,
  index,
  openModal,
  ...rest
}) => {
  const { t } = useTranslation();

  const inspirationIsActive = countryToUpdate.inspirationIds.includes(scene.id);

  const [isChecked, setIsChecked] = useState(inspirationIsActive);

  const updateBrandCountryMutation = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
  });

  useEffect(() => {
    setIsChecked(inspirationIsActive);
  }, [inspirationIsActive]);

  const toggleLanguage = () => {
    updateBrandCountryMutation.mutate({
      id: countryToUpdate.id,
      brandCountry: {
        ...countryToUpdate,
        inspirationIds: inspirationIsActive
          ? countryToUpdate.inspirationIds.filter((id) => id !== scene.id)
          : [...countryToUpdate.inspirationIds, scene.id],
      },
    });
    setIsChecked(!inspirationIsActive);
  };

  return (
    <Grid
      alignItems='center'
      gap='0'
      templateColumns='auto minmax(0, 1fr) auto'
      px='8'
      py='4'
      bg='background'
      border='1px solid'
      borderColor='border'
      borderRadius='md'
      {...rest}
    >
      <HStack gap='6'>
        <Text>{index + 1}</Text>
        <Image
          boxSize='4.5rem'
          border='1px solid'
          borderColor='border'
          borderRadius='8px'
          objectFit='cover'
          src={endpointFactory.sceneImageUrl({ id: scene.id })}
        />
      </HStack>

      <Grid
        alignItems='center'
        templateColumns='minmax(0, 1fr) auto minmax(0, 1fr)'
        w='full'
        px='6'
      >
        <Stack gap='0'>
          <Text color='lighterText'>ID {scene.id}</Text>
          <Text fontWeight='bold'>{scene.name}</Text>
        </Stack>

        <Text>
          {t('admin.inspirations.perspectiveAmount', {
            count: scene.viewCount,
          })}
        </Text>

        <HStack justifyContent='flex-end'>
          <HStack>
            <Switch
              disabled={updateBrandCountryMutation.isPending}
              isChecked={isChecked}
              onChange={toggleLanguage}
            />
            <Text>{t('admin.inspirations.isActive')}</Text>
          </HStack>
        </HStack>
      </Grid>

      <Button
        w='fit-content'
        fontSize='sm'
        aria-label='refresh'
        leftIcon={<Icon icon='zoom_out_map' w='14px' />}
        onClick={() => openModal(scene)}
        variant='tertiary'
      >
        {t('admin.inspirations.preview')}
      </Button>
    </Grid>
  );
};
