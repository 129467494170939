import { Stack } from '@chakra-ui/react';

import { CountryAdmins } from '$/pages/AdminPages/pages/UserPage/components/CountryAdmins';
import { UserPageHeader } from '$/pages/AdminPages/pages/UserPage/components/UserPageHeader';

export const AdminUserPage = () => {
  return (
    <Stack gap='4' overflowY='auto' w='full' p='8'>
      <UserPageHeader />
      <CountryAdmins />
    </Stack>
  );
};
