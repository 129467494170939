import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CountryAdminInspirationPage } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage';
import { SuperAdminInspirationPage } from '$/pages/AdminPages/pages/InspirationsPage/pages/SuperAdminInspirationPage';

export const AdminInspirationPage = () => {
  const userRole = useAuthenticationStore.useUserRole();

  if (userRole === 'superAdmin') {
    return <SuperAdminInspirationPage />;
  }

  if (userRole === 'countryAdmin') {
    return <CountryAdminInspirationPage />;
  }
};
