import { createRoute } from '@tanstack/react-router';

import { AdminLanguagesPage } from '$/pages/AdminPages/pages/LanguagesPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminLanguagesRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/languages',
  component: AdminLanguagesPage,
});
