import { createRoute, redirect } from '@tanstack/react-router';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { BaseAdminLayout } from '$/components/layouts/BaseAdminLayout';
import { NotFoundPage } from '$/pages/NotFoundPage';
import { RootRoute } from '$/routes/RootRoute';
import { UserRole } from '$/services/usecases/authentication/mapper/jwt';

const allowedRoles: UserRole[] = ['superAdmin', 'countryAdmin'];

export const AdminBaseRouteLayout = createRoute({
  notFoundComponent: NotFoundPage,
  getParentRoute: () => RootRoute,
  beforeLoad: () => {
    const { user } = useAuthenticationStore.getState();

    if (!allowedRoles.includes(user?.role ?? 'free')) {
      redirect({ to: '/not-allowed', throw: true });
    }
  },
  onLeave: () => {
    location.reload();
  },
  component: BaseAdminLayout,
  id: 'adminBaseLayout',
});
