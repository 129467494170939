import { Button, Grid, HStack, Switch, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useUpdateLanguageFile } from '$/pages/AdminPages/pages/LanguagesPage/hooks/useUpdateLanguageFile';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { AdminLanguage } from '$/services/usecases/admin/mapper/languages';
import { countriesQuery } from '$/services/usecases/internationalization/countries';

interface Props {
  language: AdminLanguage;
  countryToUpdate: BrandCountry;
}

export const CountryAdminLanguageCard: FC<Props> = ({
  language,
  countryToUpdate,
}) => {
  const { t } = useTranslation();

  const languageIsActive = countryToUpdate.languageIds.includes(language.id);

  const user = useAuthenticationStore.useUser();
  const [isChecked, setIsChecked] = useState(languageIsActive);

  const languageFileMutation = useUpdateLanguageFile();

  const updateBrandCountryMutation = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: countriesQuery.queryKey,
      });
    },
  });

  useEffect(() => {
    setIsChecked(languageIsActive);
  }, [languageIsActive]);

  const toggleLanguage = () => {
    const newLanguageIds = countryToUpdate.languageIds.includes(language.id)
      ? countryToUpdate.languageIds.filter((id) => id !== language.id)
      : [...countryToUpdate.languageIds, language.id];

    updateBrandCountryMutation.mutate({
      id: countryToUpdate.id,
      brandCountry: { ...countryToUpdate, languageIds: newLanguageIds },
    });

    setIsChecked(!language.isActive);
  };

  return (
    <HStack
      gap='0'
      px='8'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='md'
    >
      <Grid
        templateColumns='minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
        w='full'
      >
        <HStack>
          <Text fontWeight='bold'>{t(`languages.${language.id}`)}</Text>
        </HStack>

        <HStack justify='center' gap='8'>
          <HStack>
            <Switch
              disabled={updateBrandCountryMutation.isPending}
              isChecked={isChecked}
              onChange={toggleLanguage}
            />
            <Text> {t('admin.languages.isActive')}</Text>
          </HStack>
        </HStack>

        <HStack justify='flex-end' minH='10'>
          {user?.poEditorPermissions?.includes(language.id) && (
            <Button
              w='fit-content'
              fontSize='sm'
              aria-label='refresh'
              isLoading={languageFileMutation.isPending}
              leftIcon={<Icon icon='refresh' w='14px' />}
              onClick={() => languageFileMutation.mutate(language.id)}
              placeSelf='flex-end'
              variant='secondary'
            >
              {t('admin.languages.updateLanguage')}
            </Button>
          )}
        </HStack>
      </Grid>
    </HStack>
  );
};
