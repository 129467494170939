import { queryOptions } from '@tanstack/react-query';

import { backendEndpoints } from '$/services/endpoints';
import { fetcher, STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  AdminLanguage,
  GetAllAdminLanguagesSchema,
  GetAllLanguageCodesSchema,
  CreateUpdateAdminLanguagesSchema,
  UpdateLanguageFileSchema,
} from '$/services/usecases/admin/mapper/languages';
import { validateToken } from '$/services/usecases/authentication';

export const getAllAdminLanguages = async () => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_LANGUAGES_URL, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetAllAdminLanguagesSchema.parse(data),
  });
};

export const getAllAvailableLanguageCodes = async () => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_LANGUAGES_URL}/available-codes`, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetAllLanguageCodesSchema.parse(data),
  });
};

export const createLanguage = async (languageCode: string) => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_LANGUAGES_URL, {
    method: 'POST',
    body: { languageCode },
    includeCredentials: true,
    parser: (data) => CreateUpdateAdminLanguagesSchema.parse(data),
  });
};

export const updateLanguage = async (payload: AdminLanguage) => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_LANGUAGES_URL}/${payload.id}`, {
    method: 'PATCH',
    body: {
      isActive: payload.isActive,
    },
    includeCredentials: true,
    parser: (data) => CreateUpdateAdminLanguagesSchema.parse(data),
  });
};

export const updateLanguageFile = async (languageCode: string) => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_UPDATE_LANGUAGE_FILE_URL, {
    method: 'POST',
    body: { languageCode, forceUpdate: true },
    includeCredentials: true,
    parser: (data) => UpdateLanguageFileSchema.parse(data),
  });
};

export const adminLanguagesQuery = queryOptions({
  queryKey: ['adminLanguages'],
  queryFn: () => getAllAdminLanguages(),
  staleTime: STANDARD_STALE_TIME,
});

export const availableLanguageCodesQuery = queryOptions({
  queryKey: ['adminLanguageCodes'],
  queryFn: () => getAllAvailableLanguageCodes(),
  staleTime: STANDARD_STALE_TIME,
});
