import {
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { endpointFactory } from '$/services/endpoints';
import { Scene } from '$/services/usecases/scenes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  scene: Scene | null;
}

export const InspirationPreviewModal: FC<Props> = ({
  isOpen,
  onClose,
  scene,
}) => {
  const getGridSize = () => {
    if (scene?.viewCount === 1) {
      return { templateRows: '1fr', templateColumns: '1fr' };
    }

    if (scene?.viewCount === 2) {
      return { templateRows: '1fr', templateColumns: '1fr 1fr' };
    }

    if (scene?.viewCount === 3) {
      return { templateRows: '1fr 1fr', templateColumns: '1fr 1fr' };
    }
  };

  const getGridSpan = (view: number) => {
    if (view === 0 && scene?.viewCount === 3) {
      return { gridRowStart: 1, gridRowEnd: 'span 2' };
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' w='90%' maxW='100%' h='90%' p='10'>
        <ModalHeader p='0'>
          <Button
            px='0'
            color='lighterText'
            fontSize='sm'
            leftIcon={
              <Icon w='14px' transform='rotate(180deg)' icon='arrow_right' />
            }
            onClick={onClose}
            variant='ghost'
          >
            {t('admin.inspirations.backToInspirationSection')}
          </Button>

          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7'>
            {t('admin.inspirations.preview')}
          </Text>
        </ModalHeader>

        {scene && (
          <ModalBody p='0'>
            <Grid
              gap='6'
              templateRows='1fr'
              templateColumns='1fr 1fr'
              w='100%'
              h='100%'
              {...getGridSize()}
            >
              {scene.views.map((view) => (
                <Box
                  key={view}
                  w='100%'
                  h='100%'
                  minH='100%'
                  bgImage={endpointFactory.sceneImageUrl({
                    id: scene.id,
                    view: view,
                  })}
                  bgSize='cover'
                  bgPosition='center'
                  bgRepeat='no-repeat'
                  border='1px solid'
                  borderColor='border'
                  borderRadius='8px'
                  {...getGridSpan(parseInt(view, 10))}
                />
              ))}
            </Grid>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
