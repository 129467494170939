import { Box, Grid } from '@chakra-ui/react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { FC } from 'react';

import { For } from '$/components/common/Flow/For';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialCardEditMode } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { MaterialType } from '$/services/mapper/uses';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  favorites: FavoriteItem[];
  isLoading: boolean;
  headline: string;
  materialType: MaterialType;
  editMode?: MaterialCardEditMode;
  isDragDisabled?: boolean;
  setMoveMode?: (isPaste: boolean) => void;
}

export const FavoriteGroup: FC<Props> = ({
  favorites,
  isLoading,
  headline,
  materialType,
  editMode,
  isDragDisabled = false,
  setMoveMode,
}) => {
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const itemLimit = useMaterialGridMaxItems();
  const { hasPermission } = useAuthorization();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  if (favorites.length === 0 && !isLoading) return;

  if (isLoading)
    return (
      <SectionHeadline headline={headline}>
        <Grid gap='6' templateColumns={materialTemplateColumn()}>
          {repeat(itemLimit).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        </Grid>
      </SectionHeadline>
    );

  return (
    <SectionHeadline headline={headline}>
      <Droppable
        droppableId={`materialList-${headline}`}
        direction='horizontal'
        isDropDisabled={true}
        type='group'
      >
        {(droppableProvided) => (
          <Grid
            ref={droppableProvided.innerRef}
            gap='6'
            templateColumns={materialTemplateColumn()}
          >
            <For each={favorites}>
              {(favorite, index) => (
                <Draggable
                  key={favorite.material.id}
                  draggableId={`${favorite.material.uniqueKey}&&${headline}`}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => (
                    <Box
                      key={favorite.material.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MaterialCard
                        material={favorite.material}
                        onMouseDown={(event) =>
                          setMoveMode &&
                          setMoveMode(event.ctrlKey || event.metaKey)
                        }
                        onMouseUp={() => setMoveMode && setMoveMode(false)}
                        onClick={() => {
                          if (
                            !hasPermission('Use_Pro_Materials') &&
                            favorite.material.isProMaterial
                          ) {
                            openAuthenticationModal('versionComparison');
                          } else {
                            setActiveMaterial(favorite.material);
                          }
                        }}
                        editMode={editMode}
                        materialType={materialType}
                      />
                    </Box>
                  )}
                </Draggable>
              )}
            </For>
            {droppableProvided.placeholder}
          </Grid>
        )}
      </Droppable>
    </SectionHeadline>
  );
};
