import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { User } from '$/services/usecases/authentication/mapper/genericCloudResponse';

export type ActiveEditModal = 'update_admin' | 'delete_admin';

type State = {
  editModalType: ActiveEditModal;
  isEditModalOpen: boolean;
  activeEditUser: User | null;
};

type Action = {
  setEditModalType: (type: ActiveEditModal) => void;
  onEditModalOpen: (user: User) => void;
  onEditModalClose: () => void;
};

const initial: State = {
  editModalType: 'update_admin',
  isEditModalOpen: false,
  activeEditUser: null,
};

export const store = create<State & Action>()((set) => ({
  ...initial,
  setEditModalType: (editModalType) => set({ editModalType }),
  onEditModalClose: () => {
    return set({ isEditModalOpen: false, editModalType: 'update_admin' });
  },
  onEditModalOpen: (activeEditUser) =>
    set({ isEditModalOpen: true, activeEditUser }),
}));

export const useAdminUserStore = createSelectorHooks(store);
