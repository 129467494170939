import {
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Image,
  Box,
  ModalCloseButton,
  Button,
  AbsoluteCenter,
  Portal,
  IconButton,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { BrandCountrySelection } from '$/pages/AdminPages/components/BrandCountrySelection';
import { ResizeableImage } from '$/pages/AdminPages/pages/InspirationsPage/pages/SuperAdminInspirationPage/components/ResizeableImage';
import { endpointFactory } from '$/services/endpoints';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountryProperty,
} from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { Scene } from '$/services/usecases/scenes';

interface Props {
  scene: Scene | null;
  brandCountries: BrandCountry[];
  isOpen: boolean;
  onClose: () => void;
}

export const EditInspirationModal: FC<Props> = ({
  brandCountries,
  isOpen,
  onClose,
  scene,
}) => {
  const { t } = useTranslation();
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const [fullscreenImageView, setFullscreenImageView] = useState<number | null>(
    null,
  );
  const [selectedBrandCountries, setSelectedBrandCountries] = useState<
    BrandCountry[]
  >([]);
  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  const showToast = useToast();

  const onChangeCountries: Dispatch<SetStateAction<BrandCountry[]>> = (
    state,
  ) => {
    setHasBeenChanged(true);
    setSelectedBrandCountries(state);
  };

  const onCloseModal = () => {
    if (fullscreenImageView != null) return;
    onClose();
    setFullscreenImageView(null);
  };

  useEffect(() => {
    setHasBeenChanged(false);
    if (scene == null) {
      setSelectedBrandCountries([]);
      return;
    }

    setSelectedBrandCountries(
      brandCountries.filter((brandCountry) =>
        brandCountry.inspirationIds.includes(scene.id),
      ),
    );
  }, [brandCountries, scene, t]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateBrandCountryProperty,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (res.isSuccessful) {
        onCloseModal();
        return;
      }

      showToast(
        t('admin.userManagement.addUser.generalError'),
        'error',
        cloudMessageTranslation(res.response.message),
      );
    },
  });

  const onSubmit = () => {
    if (scene == null) {
      return;
    }
    mutate({
      propertyType: 'inspirationIds',
      itemId: scene.id,
      brandCountryIds: selectedBrandCountries.map(
        (brandCountry) => brandCountry.id,
      ),
    });
  };

  if (!scene) {
    return null;
  }

  return (
    <>
      <Modal
        isCentered={true}
        isOpen={isOpen && fullscreenImageView == null}
        onClose={onCloseModal}
        size='2xl'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p='10'>
            <Button
              px='0'
              color='lighterText'
              fontSize='sm'
              leftIcon={
                <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
              }
              onClick={onCloseModal}
              variant='ghost'
            >
              {t('admin.userManagement.backToUserSection')}
            </Button>

            <ModalCloseButton top='10' right='10' />
            <Heading mb='4' fontSize='h7'>
              {t('admin.inspirations.editModal.title')}
            </Heading>
            <Text fontSize='xl' fontWeight='bold'>
              {scene.name}
            </Text>
            <HStack h='14.6875rem' mb='6'>
              <ResizeableImage
                borderRadius='12px'
                objectFit='cover'
                sceneId={scene.id}
                view={0}
                w='60%'
                h='full'
                onClick={() => setFullscreenImageView(0)}
              />

              <Box w='40%' h='full'>
                {scene.viewCount > 1 && (
                  <ResizeableImage
                    w='full'
                    h={scene.viewCount === 2 ? 'full' : '4.6875rem'}
                    borderRadius='12px'
                    objectFit='cover'
                    sceneId={scene.id}
                    view={1}
                    onClick={() => setFullscreenImageView(1)}
                  />
                )}
                {scene.viewCount > 2 && (
                  <ResizeableImage
                    w='full'
                    h='9.25rem'
                    mt='3'
                    sceneId={scene.id}
                    view={2}
                    onClick={() => setFullscreenImageView(2)}
                  />
                )}
              </Box>
            </HStack>
            <BrandCountrySelection
              selectedBrandCountries={selectedBrandCountries}
              setSelectedBrandCountries={onChangeCountries}
              allBrandCountries={brandCountries}
              descriptionText={t(
                'admin.inspirations.editModal.usedBrandCountriesDescription',
              )}
            />

            <HStack justify='space-between' mt='10'>
              <Button
                color='lighterText'
                fontSize='sm'
                isLoading={isPending}
                onClick={onCloseModal}
                variant='ghost'
              >
                {t('general.cancel')}
              </Button>
              <Button
                fontSize='sm'
                isDisabled={!hasBeenChanged}
                isLoading={isPending}
                onClick={onSubmit}
              >
                {t('admin.inspirations.editModal.saveChangesButton')}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {fullscreenImageView != null && (
        <Portal>
          <Box
            pos='absolute'
            top='0'
            left='0'
            w='100dvw'
            h='100dvh'
            bg='overlay'
          />
          <AbsoluteCenter zIndex={99999}>
            <Box p='10' bg='background' borderRadius='12px'>
              <Box pos='relative'>
                <Image
                  maxW='90dvw'
                  maxH='80dvh'
                  borderRadius='12px'
                  objectFit='cover'
                  src={endpointFactory.sceneImageUrl({
                    id: scene.id,
                    view: fullscreenImageView,
                  })}
                />
                <IconButton
                  pos='absolute'
                  top='2'
                  right='2'
                  minW='0'
                  h='auto'
                  minH='0'
                  p='1'
                  aria-label=''
                  icon={<Icon icon='zoom_in_map' boxSize='4' />}
                  onClick={() => setFullscreenImageView(null)}
                  variant='secondary'
                />
              </Box>
            </Box>
          </AbsoluteCenter>
        </Portal>
      )}
    </>
  );
};
