import {
  Button,
  Grid,
  HStack,
  Image,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useBrandCountryStore } from '$/pages/AdminPages/pages/CountriesPage/stores/useBrandCountryStore';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';

interface Props {
  country: BrandCountry;
}

export const BrandCountryCard: FC<Props> = ({ country }) => {
  const { t } = useTranslation();

  const onModalOpen = useBrandCountryStore.useOnEditModalOpen();
  const [isChecked, setIsChecked] = useState(country.isActive);

  const updateBrandQueryMutation = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
  });

  useEffect(() => {
    setIsChecked(country.isActive);
  }, [country.isActive]);

  const toggleBrandCountry = () => {
    const isActive =
      updateBrandQueryMutation.variables?.brandCountry.isActive !== undefined
        ? !updateBrandQueryMutation.variables.brandCountry.isActive
        : !country.isActive;

    updateBrandQueryMutation.mutate({
      id: country.id,
      brandCountry: { isActive },
    });

    setIsChecked(!country.isActive);
  };

  return (
    <HStack
      gap='0'
      px='8'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='md'
    >
      <Grid
        templateColumns='minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
        w='full'
      >
        <HStack gap='4'>
          {!country.isBrand && country.id !== 'xw' && (
            <Image
              w='30px'
              border='1px solid'
              borderColor='border'
              borderRadius='2px'
              alt={t(`countries.${country.id}`)}
              src={`/icons/flags/${country.id}.svg`}
            />
          )}
          {country.id === 'xw' && <Icon icon='globe' w='28px' />}
          {country.isBrand && <Icon icon='loyalty_tag' w='28px' />}
          <Stack gap='0'>
            {!country.isBrand && <Text color='lighterText'>{country.id}</Text>}
            <Text fontWeight='bold'>
              {t(`countries.${country.id}`, { defaultValue: country.id })}
            </Text>
          </Stack>
        </HStack>

        <Stack justify='center'>
          <Text>
            {country.languageIds
              .map((country) => t(`languages.${country}`))
              .join(', ')}
          </Text>
        </Stack>

        <HStack justify='flex-end' gap='8'>
          <HStack>
            <Switch
              disabled={updateBrandQueryMutation.isPending}
              isChecked={isChecked}
              onChange={toggleBrandCountry}
            />
            <Text> {t('admin.countries.isActive')}</Text>
          </HStack>

          <Button
            px='6'
            fontSize='sm'
            leftIcon={<Icon icon='edit_pen' w='14px' />}
            onClick={() => onModalOpen(country)}
            variant='tertiary'
          >
            {t('admin.countries.edit')}
          </Button>
        </HStack>
      </Grid>
    </HStack>
  );
};
