import { HStack, Grid, Text, Box, Switch } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Collection } from '$/services/usecases/collections';

interface Props {
  collection: Collection;
  isActive: boolean;
  onToggle: (collectionId: string, isActive: boolean) => void;
  isDisabled: boolean;
}

export const CollectionListItem: FC<Props> = ({
  collection,
  isActive,
  onToggle,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <HStack
      key={collection.intName}
      px='6'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
    >
      <Grid
        alignItems='center'
        templateColumns='minmax(0, 3fr) minmax(0, 3fr) minmax(0, 1fr)'
        w='full'
      >
        <Text fontWeight='bold'>{collection.producerShowName}</Text>
        <Box>
          <Text color='lighterText'>
            {t('admin.collections.materialCount', {
              count: collection.cnt,
            })}
          </Text>
          <Text fontWeight='bold'>{collection.showName}</Text>
        </Box>
        <HStack h='18px'>
          <Switch
            disabled={isDisabled}
            isChecked={isActive}
            onChange={() => onToggle(collection.intName, !isActive)}
          />
          <Text> {t('admin.collections.isActive')}</Text>
        </HStack>
      </Grid>
    </HStack>
  );
};
