import { queryOptions } from '@tanstack/react-query';

import { backendEndpoints } from '$/services/endpoints';
import { fetcher, STANDARD_STALE_TIME } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';
import {
  BrandCountry,
  GetAllBrandCountriesSchema,
  CreateUpdateBrandCountriesSchema,
} from '$/services/usecases/admin/mapper/brandCountries';
import { validateToken } from '$/services/usecases/authentication';

export const getAllBrandCountries = async () => {
  await validateToken();
  return fetcher(backendEndpoints.ADMIN_BRAND_COUNTRIES_URL, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetAllBrandCountriesSchema.parse(data),
  });
};

export const updateBrandCountry = async (payload: {
  brandCountry: Partial<BrandCountry>;
  id: string;
}) => {
  await validateToken();
  return fetcher(
    `${backendEndpoints.ADMIN_BRAND_COUNTRIES_URL}/${payload.id}`,
    {
      method: 'PATCH',
      includeCredentials: true,
      body: payload.brandCountry,
      parser: (data) => CreateUpdateBrandCountriesSchema.parse(data),
    },
  );
};

export const createBrandCountry = async (
  brandCountry: Partial<BrandCountry>,
) => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_BRAND_COUNTRIES_URL}`, {
    method: 'POST',
    includeCredentials: true,
    body: brandCountry,
    parser: (data) => CreateUpdateBrandCountriesSchema.parse(data),
  });
};

export const deleteBrandCountry = async (id: string) => {
  await validateToken();
  return fetcher(`${backendEndpoints.ADMIN_BRAND_COUNTRIES_URL}/${id}`, {
    method: 'DELETE',
    includeCredentials: true,
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const updateBrandCountryProperty = async (payload: {
  brandCountryIds: string[];
  propertyType: 'inspirationIds' | 'collectionIds' | 'producerIds';
  itemId: string;
}) => {
  await validateToken();
  return fetcher(
    `${backendEndpoints.ADMIN_BRAND_COUNTRIES_URL}/${payload.propertyType}/${payload.itemId}`,
    {
      method: 'PATCH',
      includeCredentials: true,
      body: { brandCountryIds: payload.brandCountryIds },
      parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    },
  );
};

export const brandCountriesQuery = queryOptions({
  queryKey: ['brandCountries'],
  queryFn: () => getAllBrandCountries(),
  staleTime: STANDARD_STALE_TIME,
});
