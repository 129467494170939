import { useQuery } from '@tanstack/react-query';

import {
  adminLanguagesQuery,
  availableLanguageCodesQuery,
} from '$/services/usecases/admin/languages';

export const useFilteredAvailableLanguageCodes = (query: string) => {
  const languages = useQuery(adminLanguagesQuery);
  const { data } = useQuery(availableLanguageCodesQuery);

  const existingLanguages = languages.data?.response.payload?.map(
    (language) => language.id,
  );

  const languageCodes = data?.response.payload.filter(
    (code) => !(existingLanguages ?? []).includes(code),
  );

  if (!query || query === '') {
    return {
      filteredLanguageCodes: languageCodes,
      unfilteredLanguageCodes: languageCodes,
      existingLanguages,
    };
  }

  if (languageCodes && existingLanguages) {
    const filteredLanguageCodes = languageCodes.filter((code) =>
      code.toLowerCase().includes(query.toLowerCase()),
    );

    return {
      filteredLanguageCodes,
      unfilteredLanguageCodes: data?.response.payload,
      existingLanguages,
    };
  }

  return {
    filteredLanguageCodes: [],
    unfilteredLanguageCodes: [],
    existingLanguages: [],
  };
};
