import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const AdminLanguagesSchema = z.object({
  id: z.string(),
  isActive: z.boolean(),
});

export type AdminLanguage = z.infer<typeof AdminLanguagesSchema>;

export const GetAllAdminLanguagesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.array(AdminLanguagesSchema),
});

export const GetAllLanguageCodesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.array(z.string()),
});

export const CreateUpdateAdminLanguagesSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: AdminLanguagesSchema,
});

export const UpdateLanguageFileSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.object({ percentage: z.number() }).optional(),
});
