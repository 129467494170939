import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { ContactHelpCard } from '$/pages/AdminPages/pages/ContactPage/components/ContactHelpCard';

export const AdminContactPage = () => {
  const { t } = useTranslation();

  return (
    <Stack gap='4' overflowY='auto' w='full' p='8'>
      <GeneralAdminHeader
        title={t('admin.contact.changeContact')}
        description={t('admin.contact.changeContactDescription')}
      />
      <Stack>
        <ContactHelpCard />
      </Stack>
    </Stack>
  );
};
