import { queryOptions } from '@tanstack/react-query';
import { z } from 'zod';

import { endpointFactory } from '$/services/endpoints';
import { STANDARD_STALE_TIME, veeuzeFetcher } from '$/services/fetcher';

export const CollectionSchema = z
  .object({
    status: z.number(),
    collection: z
      .object({
        intName: z.string(),
        showName: z.string(),
        cnt: z.number(),
        producerShowName: z.string(),
        producerIntName: z.string(),
        uses: z.number().array(),
      })
      .array(),
  })
  .transform((data) => data.collection);

export type Collection = z.infer<typeof CollectionSchema>[0];

export const getAllCollections = async () => {
  return await veeuzeFetcher(endpointFactory.collections(), {
    parser: CollectionSchema.parse,
    msg: 'Could not fetch collections',
  });
};

export const collectionsQuery = queryOptions({
  queryKey: ['collections'],
  queryFn: getAllCollections,
  staleTime: STANDARD_STALE_TIME,
});
