import { createRoute } from '@tanstack/react-router';

import { AdminUserPage } from '$/pages/AdminPages/pages/UserPage';
import { AdminBaseRouteLayout } from '$/routes/Admin/AdminBaseRouteLayout';

export const AdminUserManagementRoute = createRoute({
  getParentRoute: () => AdminBaseRouteLayout,
  path: '/admin/user-management',
  component: AdminUserPage,
});
